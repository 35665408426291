/*
? auth
*   scanner
*   newsavecode
? content
*   initial
*   tipps
*   mybonbons
*   scannedbonbon
*   news
*   info
? save
*   bonbonset
*   claimbonbon
*   usebonbon   
*   settings
*   readnews
*/
import { getLocalStorage } from "./helpers";

// * Live / Local
const apiUrl = "https://api.bonbon.stwda.de/";
// const apiUrl = "https://api.bonbon.local:8890/";

// const userCode = "d5ce1c95-7a97-4e48-a109-4988af40d6a2";
// const asVIP = "false";
const lang = "en";

export function authenticate(
  type: "vip" | "scanner" | "newsavecode" | "savecode",
  code: string,
  language?: "de" | "en"
) {
  const url = `${apiUrl}auth${
    type !== "vip" && type !== "savecode" ? `/${type}` : ""
  }`;
  let body = {};
  const asVIP = getLocalStorage("vip");
  if (type === "scanner") {
    body = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: `{"veranstalterZugangscode":"${code}"}`,
    };
  } else if (type === "newsavecode") {
    body = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: `{"saveCode":"${code}", "lang": "de"}`,
    };
  } else {
    body = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: `{"userCode": "${code}", "asVIP": "${
        type === "vip" ? "true" : asVIP
      }", "lang": "${language}" }`,
    };
  }

  // console.log("Url, body", url, body);
  return { url: url, body: body };
}

export function getAnalytics(password: string) {
  const url = `${apiUrl}analytics/${password}`;
  const body = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: `{}`,
  };
  return { url: url, body: body };
}

export function getContent(
  type: "initial" | "tipps" | "mybonbons" | "scannedbonbon" | "news" | "info"
) {
  const userCode = getLocalStorage("savecode");
  const url = `${apiUrl}content/${type}`;
  const asVIP = getLocalStorage("vip");
  const body = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: `{"userCode": "${userCode}", "asVIP": "${asVIP}", "lang": "${lang}" }`,
  };
  if (type === "initial") {
    return {
      url: url,
      body: {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: "{}",
      },
    };
  }
  return { url: url, body: body };
}

export function getScannedContent(
  veranstaltungsID: string,
  veranstalterZugangscode: string
) {
  const url = `${apiUrl}content/scannedbonbon`;
  const body = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: `{"veranstaltungsID":"${veranstaltungsID}","veranstalterZugangscode":"${veranstalterZugangscode}"}`,
  };
  return { url: url, body: body };
}

export function getUseBonbon(
  userCode: string,
  authCode: string,
  veranstaltungsID: string
) {
  const url = `${apiUrl}save/usebonbon`;
  const body = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: `{"userCode": "${userCode}", "veranstaltungsID": "${veranstaltungsID}", "veranstalterZugangscode": "${authCode}" }`,
  };
  return { url: url, body: body };
}

export function save(
  type:
    | "bonbonset"
    | "claimbonbon"
    | "settings"
    | "readnews"
    | "customcodesset",
  generatorCodes?: string,
  authCode?: string,
  veranstaltungsID?: string
) {
  const url = `${apiUrl}save/${type}`;
  const userCode = getLocalStorage("savecode");
  const asVIP = getLocalStorage("vip");
  const lang = getLocalStorage("lang");
  let body = {};
  switch (type) {
    case "bonbonset":
      body = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: `{"generatorCode": "${authCode}", "uuids": ${generatorCodes}}`,
      };
      break;

    case "claimbonbon":
      body = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: `{"userCode": "${userCode}", "asVIP": "${asVIP}", "veranstaltungsID": "${veranstaltungsID}" }`,
      };
      break;

    case "settings":
      body = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: `{"userCode": "${userCode}", "asVIP": "${asVIP}", "lang": "${lang}" }`,
      };
      break;

    case "readnews":
      body = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: `{"userCode": "${userCode}", "asVIP": "${asVIP}"}`,
      };
      break;

    case "customcodesset":
      body = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: `{"generatorCode": "${authCode}", "codes": ${generatorCodes}}`,
      };
      break;

    default:
      break;
  }

  return { url: url, body: body };
}
