import React, { useState, useEffect } from "react";
import BonbonCard from "../components/BonbonCard";
import BonbonGrid from "../components/BonbonGrid";
import DropdownIcon from "../components/DropdownIcon";
import * as API from "../functions/useAPI";
import {
  checkFetch,
  checkLoggedIn,
  dateInPast,
  fixDate,
  generateSlug,
  getLanguageString,
  getLocalData,
  setFetchTimeStamp,
  setLocalData,
} from "../functions/helpers";
import { IInitialResponse, IOverviewResponse } from "../Types";
import ScreenWrapper from "../components/ScreenWrapper";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
} from "@chakra-ui/react";
interface IOverviewProps {
  loginCallback: () => void;
}

const Overview = ({ loginCallback }: IOverviewProps) => {
  // TODO: DeepLink: Selected Item in URL setzen? /overview?selected=0

  const history = useHistory();

  // * Initial Data states
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [initialResponse, setInitialResponse] = useState<IInitialResponse>();
  const [initialError, setInitialError] = useState();

  // * Card Data states
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [cardsResponse, setCardsResponse] = useState<IOverviewResponse>();
  const [cardsError, setCardsError] = useState();

  // * Counts how many times reloaded for timeout
  const [reloadedInitial, setReloadedInitial] = useState(0);
  const [reloadedCards, setReloadedCards] = useState(0);

  // * Load initial
  useEffect(() => {
    let { url, body } = API.getContent("initial");

    // * Dont load again, use local storage
    if (!checkFetch("initial")) {
      const localData = getLocalData("initial");
      if (localData !== null) {
        setInitialResponse(localData);
        setInitialLoaded(true);
      } else {
        //
      }
    } else {
      // * Fetch if not loaded
      if (!initialLoaded) {
        fetch(url, body)
          .then((res) => res.json())
          .then(
            (result) => {
              setInitialResponse(result);
              // * Error in response?
              if (result.error) {
                if (!checkLoggedIn()) {
                  loginCallback();
                  history.push("/login");
                }
                const timer = setTimeout(() => {
                  console.log(
                    "Error fetching initial... Reloading #",
                    reloadedInitial
                  );
                  setReloadedInitial((reloadedInitial) => reloadedInitial + 1);
                }, 1000);
                return () => clearTimeout(timer);
              } else {
                // * Valid response
                setInitialLoaded(true);
                setLocalData("initial", result);
                setFetchTimeStamp("initial");
              }
            },
            (error) => {
              setInitialLoaded(true);
              setInitialError(error);
              console.log("Error loading data:", initialError);
              const timer = setTimeout(() => {
                console.log(
                  "Error fetching initial... Reloading #",
                  reloadedInitial
                );
                setReloadedInitial((reloadedInitial) => reloadedInitial + 1);
              }, 1000);
              return () => clearTimeout(timer);
            }
          );
      }
    }
  }, [initialLoaded, initialError, reloadedInitial]);

  // * Load cards
  useEffect(() => {
    let { url, body } = API.getContent("tipps");
    // * Dont load again, use local storage
    if (!checkFetch("tipps")) {
      const localData = getLocalData("tipps");
      if (localData !== null) {
        setCardsResponse(localData);
        setCardsLoaded(true);
      } else {
        //
      }
    } else {
      if (!cardsLoaded) {
        // console.log("Fetching cards");
        fetch(url, body)
          .then((res) => res.json())
          .then(
            (result) => {
              setCardsResponse(result);
              if (result.error) {
                if (!checkLoggedIn()) {
                  loginCallback();
                  history.push("/login");
                }
                const timer = setTimeout(() => {
                  console.log(
                    "Response Error fetching cards... Reloading #",
                    reloadedCards
                  );
                  setReloadedCards((reloadedCards) => reloadedCards + 1);
                }, 2000);
                return () => clearTimeout(timer);
              } else {
                setCardsLoaded(true);
                //  * save old data to "tipps_old_fetchedData" for comparison
                setLocalData("tipps_old", getLocalData("tipps"));
                // * save new data
                setLocalData("tipps", result);
                setFetchTimeStamp("tipps");
              }
            },
            (error) => {
              setCardsLoaded(true);
              setCardsError(error);
              console.log("Error loading cards:", cardsError);
              const timer = setTimeout(() => {
                console.log("Reloading #", reloadedCards);
                setReloadedCards((reloadedCards) => reloadedCards + 1);
              }, 2000);
              return () => clearTimeout(timer);
            }
          );
      }
    }
  }, [cardsLoaded, cardsError, reloadedCards, loginCallback, history]);

  let languageString = getLanguageString();

  React.useEffect(() => {
    // window?._paq.push(["trackPageView", window?.location.href]);
  });

  const [filter, setFilter] = useState("bonbon");
  const [filterName, setFilterName] = useState("Bonbon");

  return (
    <main className="Overview">
      <ScreenWrapper>
        <Box
          m="auto"
          maxW={{ base: "full", lg: "1024px" }}
          pb="20px"
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            width="fit-content"
            display="flex"
            gridGap="8px"
            alignItems="center"
          >
            <Box>
              {languageString === "deutsch" ? "Sortieren nach: " : "Sort by: "}
            </Box>
            <Menu>
              <MenuButton
                bg="#E3E3E3"
                height="30px"
                as={Button}
                rightIcon={<DropdownIcon />}
              >
                {filterName}
              </MenuButton>
              <MenuList>
                <MenuOptionGroup
                  defaultValue="bonbon"
                  title={
                    languageString === "deutsch" ? "Sortieren nach" : "Sort by"
                  }
                  type="radio"
                >
                  <MenuItemOption
                    value="bonbon"
                    onClick={() => {
                      setFilter("bonbon");
                      setFilterName("Bonbon");
                    }}
                  >
                    {languageString === "deutsch"
                      ? "Verfügbare Bonbons zuerst"
                      : "Available Bonbons first"}
                  </MenuItemOption>
                  <MenuItemOption
                    value="desc"
                    onClick={() => {
                      setFilter("datum");
                      setFilterName(
                        languageString === "deutsch" ? "Datum" : "Date"
                      );
                    }}
                  >
                    {languageString === "deutsch"
                      ? "Nächste Termine zuerst"
                      : "Next dates first"}
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
        </Box>
        <BonbonGrid isLoading={!cardsLoaded || !initialLoaded}>
          {cardsResponse !== undefined &&
            cardsResponse?.content !== null &&
            cardsResponse?.content !== undefined &&
            Object.keys(cardsResponse?.content)
              // * Sort by newest
              .sort(function (a: any, b: any) {
                const date_a = cardsResponse?.content[a].tipps.datum?.replace(
                  /-/g,
                  "/"
                );
                const date_b = cardsResponse?.content[b].tipps.datum?.replace(
                  /-/g,
                  "/"
                );

                const bonbon_a = cardsResponse?.content[a].bonbon.bonbon
                  ? 1
                  : -1;
                const bonbon_b = cardsResponse?.content[b].bonbon.bonbon
                  ? 1
                  : -1;

                switch (filter) {
                  case "bonbon":
                    if (bonbon_a === bonbon_b) {
                      // either both have or dont have bonbons, sort by date
                      return (
                        new Date(date_a).valueOf() - new Date(date_b).valueOf()
                      );
                    } else {
                      // one has bonbon, so bonbon on top
                      return bonbon_b - bonbon_a;
                    }
                  case "datum":
                    return (
                      new Date(date_a).valueOf() - new Date(date_b).valueOf()
                    );
                  default:
                    return (
                      new Date(date_a).valueOf() - new Date(date_b).valueOf()
                    );
                }
              })
              .map((key: any) => {
                let item = cardsResponse?.content[key];

                // * Check if required fields are not null
                if (item.tipps.inhalt[languageString].titel === null) {
                  return <></>;
                }

                return (
                  // !item.bonbon?.isClaimedByUser &&
                  <BonbonCard
                    id={item.id}
                    readMore={
                      initialResponse?.content !== undefined &&
                      initialResponse?.content !== null
                        ? initialResponse?.content.mehrlesen[languageString]
                        : ""
                    }
                    key={`bonbonCard_${item.bonbon.id}_${key}`}
                    slug={generateSlug(item.tipps.inhalt[languageString].titel)}
                    accessibility={item.tipps.accessibility}
                    date={fixDate(
                      item.tipps.datum,
                      item.tipps.tippTyp.toLowerCase()
                    )}
                    title={item.tipps.inhalt[languageString].titel}
                    type={item.tipps.tippTyp.toLowerCase()}
                    description={item.tipps.inhalt[languageString].beschreibung}
                    image={
                      item.tipps.teaserBild !== null
                        ? { src: item.tipps.teaserBild.sourceUrl }
                        : { src: "" }
                    }
                    bonbon={
                      item.bonbon.bonbon
                        ? {
                            date: item.tipps.date,
                            description:
                              item.bonbon.beschreibung[languageString],
                            title: "",
                            limit: item.bonbon.limit,
                            claimed: item.bonbon.claimed,
                            type: item.tipps.tippTyp.toLowerCase(),
                            eventID: item.databaseId,
                            isClaimedByUser: item.bonbon?.isClaimedByUser,
                          }
                        : undefined
                    }
                  />
                );
              })}
        </BonbonGrid>
      </ScreenWrapper>
    </main>
  );
};

export default Overview;
