import React, { useState, useEffect } from "react";
import { Box, Checkbox, HStack, Input, useToast } from "@chakra-ui/react";
import * as uuid from "uuid";
import Typo from "../components/Typo";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import { useHistory } from "react-router-dom";
import * as API from "../functions/useAPI";
import { IInitialResponse } from "../Types";
import {
  getLanguageString,
  getLocalData,
  setLocalStorage,
} from "../functions/helpers";
import ScreenWrapper from "../components/ScreenWrapper";
import ModalWrapper from "../components/ModalWrapper";

// TODO: initial 2 - savecode eingabe: Error (falscher Code), Error (falsche Form), "Bitte gib deinen Savecode ein", Button "Mit savecode einloggen", Savecode verloren? + Link

interface ISaveCodeProps {
  loginCallback: () => void;
}

const SaveCode = ({ loginCallback }: ISaveCodeProps) => {
  React.useEffect(() => {
    // window?._paq.push(["trackPageView", window?.location.href]);
  });

  const toast = useToast();

  const history = useHistory();
  const [code, setCode] = useState("");

  // * Auth States
  const [authLoaded, setAuthLoaded] = useState(false);
  const [authResponse, setAuthResponse] = useState({ auth: false });
  const [error, setError] = useState();

  // * Load Initial Data
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [initialResponse, setInitialResponse] = useState<IInitialResponse>();
  const [initialError, setInitialError] = useState();

  useEffect(() => {
    const { url, body } = API.getContent("initial");
    if (!initialLoaded) {
      fetch(url, body)
        .then((res) => res.json())
        .then(
          (result) => {
            setInitialLoaded(true);
            setInitialResponse(result);
            // console.log(result);
          },
          (error) => {
            setInitialLoaded(true);
            setInitialError(error);
            console.log("Error loading data:", initialError);
          }
        );
    }
  }, [initialLoaded, initialError]);

  function validateCode() {
    if (uuid.validate(code)) {
      // console.log("Code valid");
      // * Is UUID?
      if (uuid.validate(code)) {
        // * Check if valid vip
        setLocalStorage("vip", "false");
        const { url, body } = API.authenticate("savecode", code, "en");
        fetch(url, body)
          .then((res) => res.json())
          .then(
            (result) => {
              setAuthLoaded(true);
              setAuthResponse(result);
              // console.log("response", JSON.stringify(result));
              if (result.auth) {
                // * SUCCESS
                // * set local storage
                setLocalStorage("savecode", code);
                // * set logged in
                loginCallback();
                // * move to overview
                history.push("/overview");
              } else {
                // ! ERROR
                toast({
                  title: "Code existiert nicht",
                  description:
                    "Der eingegebene Savecode existiert nicht. Bitte überprüfe die Eingabe.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
            },
            (error) => {
              setAuthLoaded(true);
              setError(error);
            }
          );
      }
      if (error) {
        console.log("Error validating code:", error);
      }
    } else {
      toast({
        title: "Code nicht erkannt",
        description: "Der eingegebene Savecode hat nicht die richtige Form.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  let handleInput = (e: any) => {
    let inputValue = e.target.value;
    setCode(inputValue);
  };

  const [tosAgreed, setTosAgreed] = useState(false);

  return (
    <main className="NewCode">
      <ScreenWrapper centered>
        <Typo type="headline">Gib deinen Save-Code ein</Typo>
        <Input
          value={code}
          onSubmit={validateCode}
          onChange={handleInput}
          placeholder="cefa-2291..."
        />

        <Box mt="8" mb="4">
          <HStack>
            <Checkbox onChange={(e) => setTosAgreed(e.target.checked)} />
            <ModalWrapper
              content={
                getLocalData("initial")?.content.toc[getLanguageString()]
              }
              link={
                initialResponse?.content.nutzungsbedingungen[
                  getLanguageString()
                ]
              }
              title={
                getLanguageString() === "deutsch"
                  ? "Nutzungsbedingungen"
                  : "Terms of use"
              }
            />
          </HStack>
        </Box>
        <Button disabled={!tosAgreed} small onClick={validateCode}>
          {initialResponse?.content?.anmelden[getLanguageString()]}
        </Button>
        <Box mt="8">
          <Typo type="paragraph">
            <Link to="new-code">
              {
                initialResponse?.content?.nochkeinensavecode[
                  getLanguageString()
                ]
              }
            </Link>
          </Typo>
        </Box>
      </ScreenWrapper>
    </main>
  );
};

export default SaveCode;
