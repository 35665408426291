import * as React from "react";
import {
  Box,
  Flex,
  Image,
  Spacer,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Icon,
  TabPanel,
  Img,
} from "@chakra-ui/react";
import Typo from "./Typo";
import { Link, useHistory } from "react-router-dom";
import Badge, { IBadgeProps } from "./Badge";
import QR from "./QR";
import {
  createMarkup,
  generateSlug,
  getLanguageString,
  getLocalData,
  getLocalStorage,
} from "../functions/helpers";
import QRPreview from "../components/QRPreview";
import CloseButton from "../components/CloseButton";
import Button from "./Button";

export interface IBonbonProps {
  preview?: boolean;
  scanner?: boolean;
  date: string;
  title: string;
  description: string;
  limit?: number;
  label?: string;
  claimed?: number;
  badges?: IBadgeProps[];
  eventID?: string;
  details?: boolean;
  type: "wochentipp" | "semestertipp" | "news";
  isClaimedByUser?: boolean;
  linkProps?: any;
  externerCode?: string;
  customCode?: string;
}

const Bonbon = ({
  preview,
  scanner,
  date,
  title,
  description,
  limit,
  label,
  claimed,
  badges,
  eventID,
  details,
  type,
  isClaimedByUser,
  linkProps,
  externerCode,
  customCode,
}: IBonbonProps): JSX.Element => {
  let availability = "";
  let availabilityCheck = true;

  if (claimed !== undefined && claimed !== -1 && limit !== undefined) {
    if (limit - claimed > 0) {
      availability = `${
        limit !== undefined ? limit - claimed : claimed
      } / ${limit}`;
      availabilityCheck = true;
    } else {
      availability =
        getLocalData("initial")?.content.keineMehrVerfugbar[
          getLanguageString()
        ];
      availabilityCheck = false;
    }
  }

  const lang = getLanguageString();

  // * Check which code to display
  let displayedCode =
    lang === "deutsch" ? "Code existiert nicht." : "Code does not exist.";

  if (externerCode !== undefined && externerCode !== null) {
    // * Externer Code
    displayedCode = externerCode;
  } else if (customCode !== undefined && customCode !== null) {
    // * Custom Code
    displayedCode = customCode;
  } else if (eventID) {
    // * Normaler Code (eventID + UUID)
    displayedCode = `${eventID}_${getLocalStorage("savecode")}`;
  }

  const history = useHistory();

  return (
    <>
      {details && (
        <Center mb="4">
          <CloseButton onClick={history.goBack} />
        </Center>
      )}
      <Box
        w="100%"
        borderTop="1px"
        borderTopColor="gray.400"
        borderTopStyle={isClaimedByUser ? "none" : "dashed"}
        border={isClaimedByUser ? "1px solid rgba(0,0,0,.1)" : "  "}
        background="white"
        shadow={isClaimedByUser ? "none" : "lg"}
        opacity={isClaimedByUser ? "0" : "1"}
        display={isClaimedByUser ? "none" : "block"}
        rounded="lg"
        p="4"
        marginTop={isClaimedByUser ? "4px" : "0px"}
      >
        {preview ? (
          <Flex>
            <Box opacity={isClaimedByUser || !availabilityCheck ? "0.5" : "1"}>
              {isClaimedByUser ? (
                <Box mt="1.5">
                  <Typo type="info">
                    {getLanguageString() === "deutsch"
                      ? "Bereits gespeichert"
                      : "Already claimed"}
                  </Typo>
                </Box>
              ) : availabilityCheck ? (
                <Typo type="label">
                  {
                    getLocalData("initial")?.content?.bonbonsverfuegbar[
                      getLanguageString()
                    ]
                  }
                </Typo>
              ) : (
                ""
              )}
              {!isClaimedByUser ? (
                <Box mt={availabilityCheck ? "0" : "1.5"}>
                  <Typo type="info">{availability}</Typo>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Spacer />
            <Center>
              {isClaimedByUser ? (
                <Icon w="32px" h="32px">
                  <defs>
                    <pattern
                      id="logoFill"
                      patternUnits="userSpaceOnUse"
                      width="32"
                      height="32"
                    >
                      <image
                        href="../logo192.png"
                        x="-4"
                        y="-5"
                        width="32"
                        height="32"
                      />
                    </pattern>
                  </defs>
                  <title id="myBonbonsTitle">
                    Du hast dieses Bonbon schon gespeichert | You already saved
                    this bonbon
                  </title>
                  <svg
                    aria-labelledby="myBonbonsTitle"
                    fill="url(#logoFill)"
                    stroke="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                </Icon>
              ) : (
                <Image
                  display={!availabilityCheck ? "none" : "inherit"}
                  role="presentation"
                  alt="DAs Bonbon Logo"
                  src="../logo192.png"
                  h="32px"
                />
              )}
            </Center>
          </Flex>
        ) : (
          <>
            <Flex>
              <Box>
                <Typo type="label">
                  {
                    getLocalData("initial")?.content.meinebonbons[
                      getLanguageString()
                    ]
                  }
                </Typo>
                {badges !== undefined ? (
                  badges?.map((item, i) =>
                    item.type !== undefined ? (
                      <Badge key={item.type + i.toString()} type={item.type} />
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <Badge
                    type={type}
                    label={
                      getLocalData("initial")?.content[type.toLowerCase()][
                        getLanguageString()
                      ]
                    }
                  />
                )}
              </Box>
              <Spacer />
              <Center>
                <Img
                  role="presentation"
                  src="../logo192.png"
                  w="32px"
                  h="32px"
                />
              </Center>
            </Flex>
            {type !== "semestertipp" ? (
              <Typo type="date">{date}</Typo>
            ) : (
              <Box mt="2" />
            )}
            <Typo type="headline">{title}</Typo>
            <Flex justifyContent="space-between">
              <div dangerouslySetInnerHTML={createMarkup(description)} />
              <Box display={details || scanner ? "none" : "inherit"} ml="4">
                <QRPreview type="small" eventID={displayedCode} />
              </Box>
            </Flex>
            {scanner ? (
              limit === claimed ? (
                <Box
                  mt="4"
                  pb="4"
                  w="100%"
                  textAlign="center"
                  fontWeight="bold"
                >
                  <Typo type="paragraph">
                    {
                      getLocalData("initial")?.content.keineMehrVerfugbar[
                        getLanguageString()
                      ]
                    }
                  </Typo>
                </Box>
              ) : (
                <QR data={eventID !== undefined ? eventID : "No eventID set"} />
              )
            ) : (
              <></>
            )}
            {details && (
              <Box mt="4">
                <Box mb="4">
                  <Link
                    to={{
                      pathname: `/bonbon/${generateSlug(title)}`,
                      state: {
                        type,
                        date,
                        title,
                        slug: title,
                        image:
                          linkProps?.tipps.teaserBild !== null
                            ? { src: linkProps?.tipps.teaserBild.sourceUrl }
                            : { src: "" },
                        description:
                          linkProps?.tipps.inhalt[getLanguageString()]
                            .beschreibung,
                        badges,
                        details,
                      },
                    }}
                  >
                    <Button secondary small>
                      {getLanguageString() === "deutsch"
                        ? "Event öffnen"
                        : "Open event"}{" "}
                    </Button>
                  </Link>
                </Box>
                <Tabs isFitted variant="unstyled">
                  <TabList
                    h="32px"
                    border="2px solid"
                    borderColor="brand.900"
                    rounded="lg"
                    overflow="hidden"
                  >
                    <Tab
                      _selected={{
                        color: "white",
                        bg: "brand.900",
                        fontWeight: "bold",
                      }}
                    >
                      QR Code
                    </Tab>
                    <Tab
                      _selected={{
                        color: "white",
                        bg: "brand.900",
                        fontWeight: "bold",
                      }}
                    >
                      Barcode
                    </Tab>
                    <Tab
                      _selected={{
                        color: "white",
                        bg: "brand.900",
                        fontWeight: "bold",
                      }}
                    >
                      Text-Code
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel m="0" p="0" mt="4">
                      <Center>
                        <QRPreview type="big" eventID={displayedCode} />
                      </Center>
                    </TabPanel>
                    <TabPanel m="0" p="0" mt="4">
                      <Center>
                        <QRPreview type="barcode" eventID={displayedCode} />
                      </Center>
                    </TabPanel>
                    <TabPanel m="0" p="0" mt="4">
                      <Center minH="254px" textAlign="center">
                        {displayedCode}
                      </Center>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Bonbon;
