import * as React from "react";

import { Button as ButtonC } from "@chakra-ui/react";

export interface IButtonProps {
  secondary?: boolean;
  small?: boolean;
  fullWidth?: boolean;
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const Button = (props: React.PropsWithChildren<IButtonProps>): JSX.Element => {
  let c = "brand.900";
  let cHover = "purple.800";
  if (props.color !== undefined) {
    c = props.color;
    cHover = String(props.color).split(".")[0] + ".800";
  } else {
    if (props.secondary) {
      c = "gray.500";
      cHover = "gray.700";
    }
  }

  return (
    <ButtonC
      isDisabled={props.disabled}
      onClick={props.onClick}
      background={c}
      color="white"
      w="full"
      rounded="2xl"
      whiteSpace="normal"
      textAlign="left"
      size={props.small ? "sm" : "lg"}
      fontSize={{ base: "md", md: "xl" }}
      _hover={{ background: cHover }}
    >
      {props.children}
    </ButtonC>
  );
};

export default Button;
