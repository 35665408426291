import * as React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
} from "@chakra-ui/react";
import {
  createMarkup,
  getLanguageString,
  getLocalData,
} from "../functions/helpers";

interface IModalWrapperProps {
  content: string;
  title: string;
  link: string;
}

const ModalWrapper = ({
  content,
  title,
  link,
}: IModalWrapperProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        whiteSpace="normal"
        textAlign="left"
        variant="ghost"
        onClick={onOpen}
      >
        {link}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent ml={{ base: "4" }} mr={{ base: "4" }}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box dangerouslySetInnerHTML={createMarkup(content)} />
          </ModalBody>

          <ModalFooter>
            <Button
              background="purple.900"
              color="white"
              _hover={{ background: "purple.800" }}
              mr={3}
              onClick={onClose}
            >
              {getLocalData("initial")?.content.zurueck[getLanguageString()]}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalWrapper;
