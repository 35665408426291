import * as React from "react";
import { useState, CSSProperties } from "react";
import { v4 as uuid } from "uuid";
import {
  HStack,
  Button,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  FormControl,
  FormLabel,
  useToast,
  Code,
  Flex,
} from "@chakra-ui/react";
import { useClipboard } from "@chakra-ui/hooks";
import * as API from "../functions/useAPI";
import Text from "../components/Typo";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
    marginTop: 32,
  } as CSSProperties,
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
    background: "lightgreen",
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};

function UploadCodes() {
  // * Password Input Field
  const [pwValue, setPwValue] = useState("");

  // * Password
  const [showPW, setShowPW] = React.useState(false);

  // * Toast
  const toast = useToast();

  // * CSV Reader
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const [uploadedCodes, setUploadedCodes] = useState({
    data: [""],
    errors: [],
    meta: [],
  });

  const [uploadAccepted, setUploadAccepted] = useState(false);

  const [codesAreUploading, setCodesAreUploading] = useState(false);

  // * Tabellenname & Copy
  const [tableName, setTableName] = useState("");
  const { hasCopied, onCopy } = useClipboard(tableName);
  const [tablenameVisible, setTablenameVisible] = useState(false);

  function handleUploadSuccess(result: any) {
    setTableName(result.tableName);
    setTablenameVisible(true);
  }

  function handleCodeUpload() {
    setCodesAreUploading(true);
    console.log("Uploading...");

    let codesArrayString = "[";
    uploadedCodes.data.map((item, index) => {
      if (index !== uploadedCodes.data.length - 1) {
        codesArrayString += `"${item}",`;
      } else {
        codesArrayString += `"${item}"]`;
      }
    });

    // * Save codes to database
    const { url, body } = API.save("customcodesset", codesArrayString, pwValue);

    fetch(url, body)
      .then((res) => res.json())
      .then(
        (result) => {
          setCodesAreUploading(false);
          if (result.error) {
            toast({
              title: "Es ist ein Fehler aufgetreten :(",
              description:
                "Versuchen Sie es erneut oder überprüfen Sie Ihr Password",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Codes wurden hochgeladen",
              description:
                "Die Codes wurden erfolgreich hochgeladen und können jetzt im CMS ausgewählt werden.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            console.log("success - result: ", result);
            handleUploadSuccess(result);
          }
        },
        (error) => {
          toast({
            title: "Es ist ein Fehler aufgetreten :(",
            description:
              "Versuchen Sie es erneut oder überprüfen Sie Ihr Password",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setCodesAreUploading(false);
        }
      );
  }

  return (
    <main className="CodeGenerator">
      <Box
        pt="160px"
        pb="128px"
        pr="4"
        pl="4"
        m="auto"
        maxW={{ base: "full", lg: "1024px" }}
      >
        <Text type="paragraph">
          Hier können externe Codes hochgeladen werden. <br />
          Dafür die CSV Datei in das Feld ziehen, das CodeGenerator-Passwort
          eingeben und auf "Upload" klicken.
        </Text>

        <CSVReader
          onUploadAccepted={(results: any) => {
            setUploadedCodes(results);
            console.log("---------------------------");
            console.log(results);
            console.log("---------------------------");
            setZoneHover(false);
            setUploadAccepted(true);
            toast({
              title: "CSV Datei wurde erkannt",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }}
          onUploadRejected={(results: any) => {
            toast({
              title: "Datei kann nicht gelesen werden",
              description:
                "Wurde die richtige Datei ausgewählt? Es können nur .csv Dateien hochgeladen werden.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }}
          onDragOver={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(true);
          }}
          onDragLeave={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(false);
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
            Remove,
          }: any) => (
            <>
              <div
                {...getRootProps()}
                style={Object.assign(
                  {},
                  styles.zone,
                  zoneHover && styles.zoneHover
                )}
              >
                {acceptedFile ? (
                  <>
                    <div style={styles.file}>
                      <div style={styles.info}>
                        <span style={styles.size}>
                          {formatFileSize(acceptedFile.size)}
                        </span>
                        <span style={styles.name}>{acceptedFile.name}</span>
                      </div>
                      <div style={styles.progressBar}>
                        <ProgressBar />
                      </div>
                      <div
                        role="button"
                        tabIndex={-1}
                        {...getRemoveFileProps()}
                        style={styles.remove}
                        onMouseOver={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                        onFocus={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                        onBlur={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                        onMouseOut={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  </>
                ) : (
                  <Box mt="32px" mb="32px" cursor="pointer">
                    <Text type="paragraph">
                      Hier CSV Datei reinziehen oder klicken
                    </Text>
                  </Box>
                )}
              </div>
            </>
          )}
        </CSVReader>

        {uploadedCodes.data.length > 0 && uploadAccepted && (
          <>
            <Code mt="32px" p="16px" rounded="12px">
              Anzahl der Zeilen: <b>{uploadedCodes.data.length}</b>
              <br />
              <br />
              <b>Ausschnitt aus der Datei:</b>
              <br />
              Zeile 1: {uploadedCodes.data[0]}
              <br />
              Zeile 2: {uploadedCodes.data[1]}
              <br />
              ...
              <br />
              Zeile {uploadedCodes.data.length - 1}:{" "}
              {uploadedCodes.data[uploadedCodes.data.length - 2]}
              <br />
              Zeile {uploadedCodes.data.length}:{" "}
              {uploadedCodes.data[uploadedCodes.data.length - 1]}
            </Code>
          </>
        )}

        <FormControl id="Password" mt="56px">
          <FormLabel>Geben Sie das CodeGenerator-Passwort ein</FormLabel>
          <InputGroup size="md">
            <Input
              value={pwValue}
              pr="4.5rem"
              type={showPW ? "text" : "password"}
              placeholder="Passwort eingeben"
              onChange={(event) => {
                setPwValue(event.target.value);
              }}
            />
            <InputRightElement width="4.5rem" mr="8px">
              <Button h="1.75rem" size="sm" onClick={() => setShowPW(!showPW)}>
                {showPW ? "Verstecken" : "Anzeigen"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Box pt="56px" m="auto" w="fit-content">
          <Button
            m="auto"
            isLoading={codesAreUploading}
            loadingText="Codes werden hochgeladen..."
            onClick={() => handleCodeUpload()}
            colorScheme="green"
            size="lg"
            disabled={!uploadAccepted}
          >
            Codes hochladen
          </Button>
        </Box>

        <Box pt="56px" m="auto" w="100%">
          <FormLabel>
            Nach dem Upload kann der Tabellenname kopiert werden, um ihn im CMS
            in der gewünschten Veranstaltung einzutragen.
          </FormLabel>
          <Flex mb={2}>
            <Input
              disabled={!tablenameVisible}
              value={tableName}
              isReadOnly
              placeholder="Tabellenname"
            />
            <Button disabled={!tablenameVisible} onClick={onCopy} ml={2}>
              {hasCopied ? "Kopiert" : "Kopieren"}
            </Button>
          </Flex>
        </Box>
      </Box>
    </main>
  );
}

export default UploadCodes;
