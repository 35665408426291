import React, { useEffect, useState } from "react";
import { IInitialResponse, IOverviewResponse } from "../Types";
import * as API from "../functions/useAPI";
import {
  checkFetch,
  checkLoggedIn,
  dateInPast,
  fixDate,
  generateSlug,
  getLanguageString,
  getLocalData,
  setLocalData,
} from "../functions/helpers";
import BonbonGrid from "../components/BonbonGrid";
import { Link, useHistory } from "react-router-dom";
import Bonbon from "../components/Bonbon";
import ScreenWrapper from "../components/ScreenWrapper";
import { Center } from "@chakra-ui/react";
import Typo from "../components/Typo";

interface IMyBonbonsProps {
  loginCallback: () => void;
}

const MyBonbons = ({ loginCallback }: IMyBonbonsProps) => {
  useEffect(() => {
    // window?._paq.push(["trackPageView", window?.location.href]);
  });
  // TODO: DeepLink: Selected Item in URL setzen?

  const history = useHistory();

  // * Initial Data states
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [initialResponse, setInitialResponse] = useState<IInitialResponse>();
  const [initialError, setInitialError] = useState();
  // * Card Data states
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [cardsResponse, setCardsResponse] = useState<IOverviewResponse>();
  const [cardsError, setCardsError] = useState();

  const [reload, setReload] = useState(false);

  // * Load initial
  useEffect(() => {
    let { url, body } = API.getContent("initial");

    // * Dont load again, use local storage
    if (!checkFetch("initial")) {
      const localData = getLocalData("initial");
      if (localData !== null) {
        setInitialResponse(localData);
        setInitialLoaded(true);
      } else {
        //
      }
    } else {
      // * Fetch if not loaded
      if (!initialLoaded) {
        fetch(url, body)
          .then((res) => res.json())
          .then(
            (result) => {
              setInitialResponse(result);
              // * Error in response?
              if (result.error) {
                if (!checkLoggedIn()) {
                  loginCallback();
                  history.push("/login");
                }
                const timer = setTimeout(() => {
                  console.log("Reloading...");
                  setReload(!reload);
                }, 1000);
                return () => clearTimeout(timer);
              } else {
                setInitialLoaded(true);
                setLocalData("initial", result);
              }
            },
            (error) => {
              setInitialLoaded(true);
              setInitialError(error);
              console.log("Error loading data:", initialError);
              const timer = setTimeout(() => {
                console.log("Reloading...");
                setReload(!reload);
              }, 1000);
              return () => clearTimeout(timer);
            }
          );
      }
    }
  }, [initialLoaded, initialError, reload]);

  // * Load cards
  useEffect(() => {
    const { url, body } = API.getContent("mybonbons");
    if (!cardsLoaded) {
      fetch(url, body)
        .then((res) => res.json())
        .then(
          (result) => {
            setCardsResponse(result);
            setCardsLoaded(true);
          },
          (error) => {
            setCardsLoaded(true);
            setCardsError(error);
            console.log("Error loading data:", cardsError);
          }
        );
    }
  }, [cardsLoaded, cardsError]);

  let languageString = getLanguageString();

  return (
    <main className="MyBonbons">
      <ScreenWrapper>
        <BonbonGrid isLoading={!cardsLoaded || !initialLoaded}>
          {cardsResponse !== undefined &&
            cardsResponse?.content !== null &&
            cardsResponse?.content !== undefined &&
            Object.keys(cardsResponse?.content)
              .sort(function (a: any, b: any) {
                // Newest Date First
                return (
                  new Date(cardsResponse?.content[b].tipps.datum).valueOf() -
                  new Date(cardsResponse?.content[a].tipps.datum).valueOf()
                );
              })
              .map((key: any) => {
                let item = cardsResponse?.content[key];
                return !dateInPast(item.bonbon.ablaufdatum) ? (
                  <Link
                    key={`mybonbon_${key}`}
                    to={{
                      pathname: `/bonbonDetail/${generateSlug(
                        item.tipps.inhalt[languageString].titel
                      )}`,
                      state: {
                        title: item.tipps.inhalt[languageString].titel,
                        eventID: item.databaseId,
                        description: item.bonbon.beschreibung[languageString],
                        date: fixDate(
                          item.tipps.datum,
                          item.tipps.tippTyp.toLowerCase()
                        ),
                        type: item.tipps.tippTyp.toLowerCase(),
                        limit:
                          item.bonbon.limit !== undefined
                            ? item.bonbon.limit
                            : -1,
                        claimed:
                          item.bonbon.claimed !== undefined
                            ? item.bonbon.claimed
                            : -1,
                        linkProps: item,
                        externerCode: item.bonbon.externerGutscheincode,
                        customCode: item.bonbon.externeCustomGutscheintabelle,
                      },
                    }}
                  >
                    <Bonbon
                      key={`mybonbon_b_${key}`}
                      description={item.bonbon.beschreibung[languageString]}
                      title={item.tipps.inhalt[languageString].titel}
                      date={fixDate(
                        item.tipps.datum,
                        item.tipps.tippTyp.toLowerCase()
                      )}
                      eventID={item.databaseId}
                      type={item.tipps.tippTyp.toLowerCase()}
                      label={
                        initialResponse?.content !== undefined
                          ? initialResponse?.content.meinebonbons[
                              languageString
                            ]
                          : "Meine Bonbons"
                      }
                      limit={
                        item.bonbon.limit !== undefined ? item.bonbon.limit : -1
                      }
                      claimed={
                        item.bonbon.claimed !== undefined
                          ? item.bonbon.claimed
                          : -1
                      }
                      linkProps={item}
                      externerCode={item.bonbon.externerGutscheincode}
                      customCode={item.bonbon.externeCustomGutscheintabelle}
                    />
                  </Link>
                ) : (
                  <React.Fragment key={`mybonbon_${key}`}></React.Fragment>
                );
              })}
        </BonbonGrid>
        {cardsLoaded &&
          initialLoaded &&
          cardsResponse?.content !== undefined &&
          cardsResponse?.content !== null &&
          cardsResponse?.content.length === 0 && (
            <Center
              w="100%"
              h="100vh"
              pos="absolute"
              top="0"
              left="0"
              textAlign="center"
            >
              <Typo type="label">
                {
                  initialResponse?.content?.duHastNochKeineBonbonsGespeichert[
                    languageString
                  ]
                }
              </Typo>
            </Center>
          )}
      </ScreenWrapper>
    </main>
  );
};

export default MyBonbons;
