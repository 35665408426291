import * as React from "react";

import { Button as ButtonC, Icon } from "@chakra-ui/react";

export interface IButtonProps {
  onClick: () => void;
  color?: string;
}

const CloseButton = ({ onClick, color }: IButtonProps): JSX.Element => {
  let c = "purple.900";
  let cHover = "purple.800";
  if (color !== undefined) {
    c = color;
    cHover = String(color).split(".")[0] + ".800";
  }

  return (
    <ButtonC
      p="0"
      rounded="full"
      onClick={onClick}
      background={c ? c : "brand.900"}
      _hover={{ background: cHover }}
      color="white"
      size="sm"
      aria-label="Zurück | Back"
    >
      <Icon>
        <title id="closeTitle">Zurück | Back</title>
        <svg
          aria-labelledby="closeTitle"
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </Icon>
    </ButtonC>
  );
};

export default CloseButton;
