import * as React from "react";
import QrReader from "react-qr-reader";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import Button from "./Button";
import Typo from "./Typo";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as uuid from "uuid";
import {
  getLanguageString,
  getLocalData,
  isMobile,
} from "../functions/helpers";

export interface IQRScanProps {
  openString: string;
  successString?: string;
  successLocation?: string;
  callback?: (data: any) => void;
}

const QRScan = ({
  openString,
  successString,
  successLocation,
  callback,
}: IQRScanProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // TODO: Initial 2 "Bitte den Code Scannen"
  const [QRData, setQRData] = useState("");
  const [scanned, setScanned] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const handleScan = (data: any) => {
    if (data) {
      if (uuid.validate(data) || uuid.validate(data.split("_")[1])) {
        setScanned(true);
        setQRData(data);
        callback && callback(data);
        onClose();
      } else {
        if (!toastOpen) {
          setToastOpen(true);
          // TODO: Initial 2 : "QR Code ist kein Bonbon-Code" Toast
          toast({
            title: "QR Code ist kein Bonbon-Code.",
            description:
              "Der gescannte QR Code ist kein Bonbon-Code. Versuche es bitte nocheinmal.",
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => setToastOpen(false),
          });
        }
      }
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  return (
    <Box>
      <Flex bg="white" p="2" mb="2" rounded="xl">
        {scanned && (
          <Typo type="paragraph">
            <b>Code:</b> <i>{QRData}</i>
          </Typo>
        )}
        <Spacer />
        {/* {scanned ? (
          <Icon w="20px" h="20px" bg="green.500" rounded="full" color="white">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </Icon>
        ) : (
          <></>
        )} */}
      </Flex>
      {!scanned && (
        <Button small onClick={onOpen}>
          {openString}
        </Button>
      )}
      {scanned && successString !== undefined && successLocation !== undefined && (
        <Link to={successLocation}>
          <Button small onClick={() => {}}>
            {successString}
          </Button>
        </Link>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent ml="4" mr="4">
          <ModalHeader>QR Code scannen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              facingMode={isMobile() ? "environment" : "user"}
            />
          </ModalBody>
          <ModalFooter>
            <Typo type="paragraph">{QRData}</Typo>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default QRScan;
