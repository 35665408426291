import React, { useState, useEffect } from "react";
import BonbonCard from "../components/BonbonCard";
import * as API from "../functions/useAPI";
import BonbonGrid from "../components/BonbonGrid";
import {
  checkFetch,
  checkLoggedIn,
  fixDate,
  getLanguageString,
  getLocalData,
  getLocalStorage,
  setFetchTimeStamp,
  setLocalData,
} from "../functions/helpers";
import { IInitialResponse } from "../Types";
import ScreenWrapper from "../components/ScreenWrapper";
import { useHistory } from "react-router-dom";

interface INewsProps {
  loginCallback: () => void;
}

const News = ({ loginCallback }: INewsProps) => {
  // TODO: DeepLink: Selected Item in URL setzen? /overview?selected=0

  // * Check if logged in
  const history = useHistory();

  // * News Data states
  const [newsLoaded, setNewsLoaded] = useState(false);
  const [newsResponse, setNewsResponse] = useState<IInitialResponse>();
  const [newsError, setNewsError] = useState();
  const [reload, setReload] = useState(false);

  // * Load News
  useEffect(() => {
    let { url, body } = API.getContent("news");
    // * Dont load again, use local storage
    if (!checkFetch("news")) {
      const localData = getLocalData("news");
      if (localData !== null) {
        setNewsResponse(localData);
        setNewsLoaded(true);
      } else {
        //
      }
    } else {
      // * Fetch if not loaded
      if (!newsLoaded) {
        // console.log("Fetching news");
        fetch(url, body)
          .then((res) => res.json())
          .then(
            (result) => {
              setNewsResponse(result);
              // * Error in response?
              if (result.error) {
                if (!checkLoggedIn()) {
                  loginCallback();
                  history.push("/login");
                }
                const timer = setTimeout(() => {
                  // console.log("Error fetching initial... Reloading");
                  setReload(!reload);
                }, 1000);
                return () => clearTimeout(timer);
              } else {
                // * Valid response
                setNewsLoaded(true);
                setLocalData("news", result);
                setFetchTimeStamp("news");
              }
            },
            (error) => {
              setNewsLoaded(true);
              setNewsError(error);
              // console.log("Error loading news:", newsError);
            }
          );
      }
    }
  }, [newsLoaded, newsError, reload]);

  let languageString = getLanguageString();

  // Read News
  useEffect(() => {
    let { url, body } = API.save("readnews");
    fetch(url, body)
      .then((res) => res.json())
      .then(
        (result) => {
          if (getLocalData("news")) {
            let temporaryLastReadNewsChange = getLocalData("news");
            if (temporaryLastReadNewsChange !== null) {
              temporaryLastReadNewsChange.lastReadNews = new Date().toString();
              setLocalData("news", temporaryLastReadNewsChange);
            }
          }
        },
        (error) => {
          // console.log("Error loading data:", error);
        }
      );
  }, []);

  function checkIfNewNews(datum: any, newsResponse: any) {
    if (newsResponse) {
      const lastReadNews = new Date(newsResponse.lastReadNews);
      let newsDate = new Date(datum);
      // News date ist neuer als lastreadNews
      if (newsDate >= lastReadNews) {
        return "notRead";
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  return (
    <main className="News">
      {/* News Screen {query.get("selected")} */}
      <ScreenWrapper>
        <BonbonGrid isLoading={!newsLoaded}>
          {newsResponse?.content !== null &&
            newsResponse?.content !== undefined &&
            newsResponse?.content
              .sort(function (a: any, b: any) {
                // Newest Date First
                return (
                  new Date(b.news.datum).valueOf() -
                  new Date(a.news.datum).valueOf()
                );
              })
              .map((item: any) => {
                return (
                  <BonbonCard
                    key={`news_${item.databaseId}`}
                    readMore={
                      getLocalData("initial")?.content !== undefined &&
                      getLocalData("initial")?.content !== null
                        ? getLocalData("initial")?.content.mehrlesen[
                            languageString
                          ]
                        : ""
                    }
                    slug={item.news.inhalt[languageString].titel}
                    date={item.news.datum && fixDate(item.news.datum, "news")}
                    title={item.news.inhalt[languageString].titel}
                    badges={[
                      { type: "news" },
                      { type: item.news.newsKategorie },
                      { type: checkIfNewNews(item.news.datum, newsResponse) },
                    ]}
                    type="news"
                    description={item.news.inhalt[languageString].beschreibung}
                    image={{ src: "" }}
                  />
                );
              })}
        </BonbonGrid>
      </ScreenWrapper>
    </main>
  );
};

export default News;
