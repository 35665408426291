import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  VStack,
  HStack,
  Wrap,
  Image,
  Center,
  Icon,
} from "@chakra-ui/react";
import Typo from "./Typo";
import Button from "./Button";
import Badge, { IBadgeProps } from "./Badge";
import Bonbon, { IBonbonProps } from "./Bonbon";
import CloseButton from "./CloseButton";
import { IImage } from "../Types";
import AccessibilityIcon from "./AccessibilityIcon";
import {
  checkIsBonbonNew,
  createMarkup,
  fixDate,
  getLanguageString,
  getLocalData,
} from "../functions/helpers";

export interface IBonbonCardProps {
  type: "wochentipp" | "semestertipp" | "news";
  date: string;
  title: string;
  slug: string;
  description: string;
  bonbon?: IBonbonProps | undefined;
  image: IImage;
  badges?: IBadgeProps[];
  details?: boolean;
  accessibility?: string[];
  readMore?: string;
  id?: string;
}

const BonbonCard = ({
  type,
  date,
  title,
  slug,
  description,
  image,
  bonbon,
  badges,
  details = false,
  readMore = "Mehr lesen",
  accessibility,
  id,
}: IBonbonCardProps): JSX.Element => {
  // calc button color
  let c = "gray.500";
  switch (type) {
    case "wochentipp":
      c = "brand.900";
      break;
    case "news":
      c = "blue.900";
      break;
    case "semestertipp":
      c = "red.900";
      break;
  }

  // get badges
  const RenderedBadges = () => {
    if (type === "news" && badges !== undefined) {
      return (
        <>
          {badges?.map((item, i) =>
            item.type !== undefined ? (
              <Badge key={item.type + i.toString()} type={item.type} />
            ) : (
              <></>
            )
          )}
          {bonbon?.isClaimedByUser && <Badge type="claimed" />}
        </>
      );
    } else {
      let label =
        getLocalData("initial")?.content[type.toLowerCase()][
          getLanguageString()
        ];
      return (
        <>
          <Badge type={type} label={label} />
          {bonbon?.isClaimedByUser && <Badge type="claimed" />}
        </>
      );
    }
  };

  // * check if new
  const isNew = id ? checkIsBonbonNew(id) : false;

  // open / closed states
  let history = useHistory();

  return (
    <VStack spacing={bonbon?.isClaimedByUser ? "2" : "0"} align="left" w="100%">
      {details ? (
        <Center mb="4">
          <CloseButton
            color={type === "news" ? "blue.800" : undefined}
            onClick={history.goBack}
          />
        </Center>
      ) : (
        <></>
      )}
      <Box background="white" rounded="lg" shadow="lg">
        {type === "news" ? (
          <HStack mt="4" pl="2">
            <RenderedBadges />
          </HStack>
        ) : (
          <Box
            w="100%"
            h="128px"
            overflow="hidden"
            roundedTop="lg"
            background="gray.200"
          >
            {!details ? (
              <Link
                to={{
                  pathname: `/bonbon/${slug}`,
                  state: {
                    type,
                    date,
                    title,
                    slug,
                    description,
                    image,
                    bonbon,
                    badges,
                    details,
                    accessibility,
                  },
                }}
              >
                <Image
                  w="full"
                  h="128px"
                  fit="cover"
                  src={image.src}
                  alt={
                    image.altText
                      ? image.altText
                      : "Keine Beschreibung für das Bild angegeben"
                  }
                  title={
                    image.title
                      ? image.title
                      : "Kein Titel für das Bild angegeben"
                  }
                />
                {/* {bonbon?.isClaimedByUser && (
                  <Box
                    w="100%"
                    h="130px"
                    overflow="hidden"
                    borderRadius=".5rem .5rem 0 0"
                    position="absolute"
                    top="0"
                    left="0"
                    className="claimedAnimation"
                  >
                    <Center
                      style={{
                        width: "100%",
                        height: "130px",
                        backdropFilter: "blur(10px)",
                      }}
                      transition="all 0.2s ease-out"
                      transform="scale(1)"
                    >
                      <Icon w="100%" h="100%">
                        <defs>
                          <pattern
                            id="logoFill"
                            patternUnits="userSpaceOnUse"
                            width="32"
                            height="32"
                          >
                            <image
                              href="../logo192.png"
                              x="-4"
                              y="-5"
                              width="32"
                              height="32"
                            />
                          </pattern>
                        </defs>
                        <title id="myBonbonsTitle">
                          Du hast dieses Bonbon schon gespeichert | You already
                          saved this bonbon
                        </title>
                        <svg
                          aria-labelledby="myBonbonsTitle"
                          fill="url(#logoFill)"
                          stroke="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                          />
                        </svg>
                      </Icon>
                    </Center>
                  </Box> 
                )}*/}
              </Link>
            ) : (
              <Image
                w="full"
                h="128px"
                fit="cover"
                src={image.src}
                alt={
                  image.altText
                    ? image.altText
                    : "Keine Beschreibung für das Bild angegeben"
                }
                title={
                  image.title
                    ? image.title
                    : "Kein Titel für das Bild angegeben"
                }
              />
            )}
            <HStack position="relative" mt="-32px" pl="2">
              <RenderedBadges />
              {isNew && (
                <Badge
                  type="new"
                  label={getLanguageString() === "deutsch" ? "neu!" : "new!"}
                />
              )}
            </HStack>
          </Box>
        )}
        <Box roundedBottom="lg" w="100%" p="2">
          <Typo type="date">{date}</Typo>
          <Typo type="headline">{title}</Typo>
          <Box className="chakra-text" fontSize={{ base: "inherit", md: "lg" }}>
            {details ? (
              <div dangerouslySetInnerHTML={createMarkup(description)} />
            ) : (
              <></>
            )}
          </Box>
          <Wrap maxW="100%" mt="4" color="white">
            {accessibility?.map((item) => {
              return <AccessibilityIcon key={`${item}_${title}`} type={item} />;
            })}
          </Wrap>
          {!details ? (
            <Box mt="4" mb="4" w="100%">
              <Link
                to={{
                  pathname: `/bonbon/${slug}`,
                  state: {
                    type,
                    date,
                    title,
                    slug,
                    description,
                    image,
                    bonbon,
                    badges,
                    details,
                    accessibility,
                  },
                }}
              >
                <Button small color={c}>
                  {readMore === "" ? "Mehr lesen" : readMore}
                </Button>
              </Link>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      {bonbon ? (
        <Bonbon
          description={bonbon.description}
          title={title}
          date={date}
          preview={!details}
          badges={badges}
          type={type}
          limit={bonbon.limit !== undefined ? bonbon.limit : -1}
          claimed={bonbon.claimed !== undefined ? bonbon.claimed : -1}
          eventID={bonbon.eventID !== undefined ? bonbon.eventID : "unset"}
          isClaimedByUser={bonbon.isClaimedByUser}
          scanner
        />
      ) : (
        <></>
      )}
    </VStack>
  );
};

export default BonbonCard;
