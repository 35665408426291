import React, { useEffect, useState } from "react";
import { Center, Image, VStack, Text, Box } from "@chakra-ui/react";
import { Redirect } from "react-router-dom";

function Splash() {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setRedirect(true);
    }, 3000);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return redirect ? (
    <Redirect to="/login" />
  ) : (
    <main className="Splash">
      <Center h="100vh" pr="10%" pl="10%">
        <VStack maxW="500px">
          <Image src="stweda_logo.png" />
          <Box h="10vh" />
          <Text fontSize="0.8em" w="100%" fontWeight="bold">
            Unterstützt durch
          </Text>
          <Image src="darmstadt_logo.png" />
        </VStack>
      </Center>
    </main>
  );
}

export default Splash;
