import * as React from "react";

import { Text as TextC, Heading } from "@chakra-ui/react";

export interface ITextProps {
  type?: "headline" | "date" | "paragraph" | "info" | "label";
}

const Text = (props: React.PropsWithChildren<ITextProps>): JSX.Element => {
  switch (props.type) {
    case "headline":
      return (
        <Heading
          fontSize={{ base: "xl", md: "2xl", lg: "2xl" }}
          lineHeight="1.2em"
          mb="4"
          fontWeight="bold"
        >
          {props.children}
        </Heading>
      );
    case "date":
      return (
        <TextC
          fontSize={{ base: "md", md: "lg", lg: "lg" }}
          mt="2"
          fontWeight="regular"
        >
          {props.children}
        </TextC>
      );
    case "paragraph":
      return (
        <TextC
          fontSize={{ base: "initial", md: "md", lg: "lg" }}
          fontWeight="regular"
        >
          {props.children}
        </TextC>
      );
    case "info":
      return (
        <TextC fontSize="sm" fontWeight="bold" color="gray.00">
          {props.children}
        </TextC>
      );
    case "label":
      return (
        <TextC
          textTransform="uppercase"
          fontSize={{ base: "xs", md: "sm", lg: "sm" }}
          fontWeight="bold"
          color="gray.600"
        >
          {props.children}
        </TextC>
      );
    default:
      return <TextC>{props.children}</TextC>;
  }
};

export default Text;
