import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
  Checkbox,
  HStack,
} from "@chakra-ui/react";
import Switch from "../components/Switch";
import Typo from "../components/Typo";
import Button from "../components/Button";
import {
  setLocalStorage,
  getLocalStorage,
  getLanguageString,
  setFetchTimeStamp,
  setLocalData,
  getLocalData,
} from "../functions/helpers";
import * as API from "../functions/useAPI";
import { IInitialResponse } from "../Types";
import QRScan from "../components/QRScan";
import * as uuid from "uuid";
import ScreenWrapper from "../components/ScreenWrapper";
import ModalWrapper from "../components/ModalWrapper";

interface ILoginProps {
  loginCallback: () => void;
}

const Login = ({ loginCallback }: ILoginProps) => {
  React.useEffect(() => {
    // window?._paq.push(["trackPageView", window?.location.href]);
  });

  const history = useHistory();

  const [language, setLanguage] = useState(getLocalStorage("lang"));

  function handleLanguageSwitch(lang: string) {
    console.log("Changing language to ", lang);
    setLocalStorage("lang", lang);
    setLanguage(lang);
  }

  // * Auth States
  const [authLoaded, setAuthLoaded] = useState(false);
  const [authResponse, setAuthResponse] = useState({ auth: false });
  const [error, setError] = useState();
  const [authValid, setAuthValid] = useState(false);

  // * Load Initial Data
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [initialResponse, setInitialResponse] = useState<IInitialResponse>();
  const [initialError, setInitialError] = useState();

  useEffect(() => {
    const { url, body } = API.getContent("initial");
    if (!initialLoaded) {
      fetch(url, body)
        .then((res) => res.json())
        .then(
          (result) => {
            setInitialLoaded(true);
            setLocalData("initial", result);
            setFetchTimeStamp("initial");
            setInitialResponse(result);
          },
          (error) => {
            setInitialLoaded(true);
            setInitialError(error);
            console.log("Error loading data:", initialError);
          }
        );
    }
    // * Auth valid?
    if (authLoaded && authResponse.auth && !authValid) {
      setAuthValid(true);
    }
  }, [initialLoaded, initialError, authLoaded, authValid, authResponse.auth]);

  let languageString = getLanguageString();

  // * Check VIP Code
  function handleVipCode(data: any) {
    // * Is UUID?
    if (uuid.validate(data)) {
      // * Check if valid vip
      const { url, body } = API.authenticate("vip", data, "en");
      fetch(url, body)
        .then((res) => res.json())
        .then(
          (result) => {
            setAuthLoaded(true);
            setAuthResponse(result);
            setLocalStorage("savecode", data);
            setLocalStorage("vip", "true");
          },
          (error) => {
            setAuthLoaded(true);
            setError(error);
          }
        );
    }
    if (error) {
      console.log("Error validating code:", error);
    }
  }

  useEffect(() => {
    const l = getLocalData("lang");
    if (l === undefined || l === null) {
      handleLanguageSwitch("de");
    }
  }, []);

  const [tosAgreed, setTosAgreed] = useState(false);

  return (
    <main className="Login">
      {initialLoaded && initialResponse?.content !== undefined && (
        <ScreenWrapper centered>
          <Switch
            actionLeft={() => handleLanguageSwitch("de")}
            actionRight={() => handleLanguageSwitch("en")}
            textLeft="Deutsch"
            textRight="English"
            active={getLocalStorage("lang") === "en" ? "right" : "left"}
          />
          <Box mt="6">
            <Typo type="headline">
              {initialResponse?.content?.hastDuEinenVipCode[languageString]}
            </Typo>
            <Box>
              <Tabs isFitted variant="unstyled">
                <TabList
                  h="32px"
                  border="2px solid"
                  borderColor="brand.900"
                  rounded="2xl"
                >
                  <Tab
                    _selected={{
                      color: "white",
                      bg: "brand.900",
                      rounded: "xl",
                      fontWeight: "bold",
                    }}
                  >
                    {initialResponse?.content.ja[languageString]}
                  </Tab>
                  <Tab
                    _selected={{
                      color: "white",
                      bg: "brand.900",
                      rounded: "xl",
                      fontWeight: "bold",
                    }}
                  >
                    {initialResponse?.content.nein[languageString]}
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel m="0" p="0" mt="4">
                    <QRScan
                      openString={
                        getLocalData("initial")?.content.qrcodescannen[
                          getLanguageString()
                        ]
                      }
                      callback={handleVipCode}
                    />
                    <Box display={authValid ? "inherit" : "none"}>
                      <Box mt="8" mb="4">
                        <HStack>
                          <Checkbox
                            onChange={(e) => setTosAgreed(e.target.checked)}
                          />
                          <ModalWrapper
                            content={
                              getLocalData("initial")?.content.toc[
                                getLanguageString()
                              ]
                            }
                            link={
                              initialResponse?.content.nutzungsbedingungen[
                                languageString
                              ]
                            }
                            title={
                              getLanguageString() === "deutsch"
                                ? "Nutzungsbedingungen"
                                : "Terms of use"
                            }
                          />
                        </HStack>
                      </Box>
                      <Button
                        small
                        disabled={!tosAgreed}
                        onClick={() => {
                          loginCallback();
                          history.push("/overview");
                        }}
                      >
                        {initialResponse?.content.vipzugang[languageString]}
                      </Button>
                    </Box>
                  </TabPanel>
                  <TabPanel m="0" p="0" mt="4">
                    <Link to="/save-code">
                      <Button small onClick={() => {}}>
                        {
                          // TODO: Initial 2 - login with save-code
                        }
                        Save-Code Zugang
                      </Button>
                    </Link>
                    <Divider mt="8" mb="8" />
                    <Link to="/new-code">
                      <Button small secondary onClick={() => {}}>
                        Ich habe noch keinen Save-Code
                      </Button>
                    </Link>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
            <Box mt="8">
              <Typo type="paragraph">
                {initialResponse?.content.savecodehinweis[languageString]}
              </Typo>
            </Box>
            <Box position="absolute" bottom="0">
              <ModalWrapper
                content={
                  getLocalData("initial")?.content.impressum[
                    getLanguageString()
                  ]
                }
                title={
                  getLanguageString() === "deutsch"
                    ? "Impressum"
                    : "Legal Notice"
                }
                link={
                  getLanguageString() === "deutsch"
                    ? "Impressum"
                    : "Legal Notice"
                }
              />
            </Box>
          </Box>
        </ScreenWrapper>
      )}
    </main>
  );
};

export default Login;
