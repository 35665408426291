import * as React from "react";
import { createMarkup } from "../functions/helpers";

import {
  Accordion as AccordionC,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

export interface IAccordionItemProps {
  title: string;
  content: string;
}

export interface IAccordionProps {
  items: IAccordionItemProps[];
}

const Accordion = ({ items }: IAccordionProps): JSX.Element => {
  return (
    <AccordionC allowToggle background="white">
      {items.map((item: IAccordionItemProps) => {
        return (
          <AccordionItem key={item.title}>
            <h2>
              <AccordionButton fontSize={{ base: "lg", md: "xl" }}>
                <Box fontWeight="bold" flex="1" textAlign="left">
                  {item.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel fontSize={{ base: "md", md: "lg" }}>
              <div dangerouslySetInnerHTML={createMarkup(item.content)} />
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </AccordionC>
  );
};

export default Accordion;
