import * as React from "react";
import { useState } from "react";
import { Box, Center, Icon, Text, useToast } from "@chakra-ui/react";
import * as API from "../functions/useAPI";
import QRCode from "react-qr-code";
import { resetFetchTimeStamp } from "../functions/helpers";

export interface IQRProps {
  data: string | number;
  showQR?: boolean;
}

const QR = ({ data, showQR }: IQRProps): JSX.Element => {
  const toast = useToast();

  const [isClaimed, setIsClaimed] = useState(false);
  const [claimLoaded, setClaimLoaded] = useState(false);
  const [error, setError] = useState();
  const [claimResponse, setClaimResponse] = useState();

  function handleClaim() {
    // * Check if valid vip
    const { url, body } = API.save("claimbonbon", "", "", data.toString());
    // console.log("claiming", url, body);
    fetch(url, body)
      .then((res) => res.json())
      .then(
        (result) => {
          setClaimLoaded(true);
          setClaimResponse(result);
          // console.log("tying to claim: ", data);
          // console.log("result", result);
          // * check result
          if (result.auth && result.success) {
            // * successfully claimed
            setIsClaimed(true);
            resetFetchTimeStamp("tipps");
            toast({
              title: "Bonbon gespeichert!",
              description: "Das Bonbon wurde zu deinen Bonbons hinzugefügt.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }
        },
        (error) => {
          setClaimLoaded(true);
          setError(error);
        }
      );
    if (error) {
      // console.log("Error claiming code:", error);
    }
  }

  return (
    <Center mt="8" mb="4">
      <Box role="button" tabIndex={0} onClick={handleClaim} w="80px" h="80px">
        <Center h="100%" position="relative">
          <Box position="absolute" zIndex={1} top="0">
            <QRCode value="placeholder" size={80} />
          </Box>
          <Box
            zIndex={2}
            opacity={isClaimed ? "0" : "1"}
            w="74px"
            h="74px"
            rounded="xl"
            background="brand.900"
          >
            <Center h="full">
              <Text
                color="white"
                fontSize="md"
                fontWeight="bold"
                textAlign="center"
                lineHeight="1.2em"
              >
                Bonbon sichern
              </Text>
            </Center>
          </Box>
          <Box
            zIndex={3}
            position="absolute"
            background="green"
            h="74px"
            w="74px"
            opacity={isClaimed ? "1" : "0"}
            transition="opacity"
            transitionTimingFunction="ease-out"
            transitionDuration="1s"
            rounded="xl"
            color="white"
          >
            <Center h="full">
              <Icon w="40px" h="40px">
                <svg
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </Icon>
            </Center>
          </Box>
        </Center>
      </Box>
    </Center>
  );
};
export default QR;
