import React, { useEffect, useState } from "react";

import { Box, Grid, Skeleton } from "@chakra-ui/react";

export interface IBonbonGridProps {
  placeholder?: boolean;
  isLoading?: boolean;
}

const BonbonGrid = (
  props: React.PropsWithChildren<IBonbonGridProps>
): JSX.Element => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (!props.isLoading) {
      setFadeIn(true);
    }
  }, [props.isLoading]);

  return (
    <Grid
      // transform={props.isLoading ? "scale(0.95)" : "scale(1)"}
      transition="all .3s ease-out"
      templateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
        md: "repeat(3, 1fr)",
      }}
      gap={{ base: "8", md: "4" }}
      m="auto"
      maxW={{ base: "full", lg: "1024px" }}
    >
      {props.isLoading ? (
        <>
          <Skeleton rounded="lg" height="220px" />
          <Skeleton rounded="lg" height="220px" />
          <Skeleton rounded="lg" height="220px" />
        </>
      ) : (
        React.Children.map(props.children, (child, i) => {
          return (
            <Box
              opacity={!fadeIn ? 0 : 1}
              transform={!fadeIn ? "scale(0.95)" : "scale(1)"}
              transition="all .3s ease-out"
              style={{ transitionDelay: `0.${i / 2}s` }}
            >
              {child}
            </Box>
          );
        })
      )}
    </Grid>
  );
};

export default BonbonGrid;
