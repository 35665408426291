import React, { useEffect, useState } from "react";
import { Box, VStack, Image, Skeleton } from "@chakra-ui/react";
import Switch from "../components/Switch";
import Accordion, { IAccordionItemProps } from "../components/Accordion";
import Typo from "../components/Typo";
import * as API from "../functions/useAPI";
import {
  setLocalStorage,
  getLocalStorage,
  checkFetch,
  getLocalData,
  setLocalData,
  setFetchTimeStamp,
  getLanguageString,
} from "../functions/helpers";
import { IInitialResponse } from "../Types";
import ScreenWrapper from "../components/ScreenWrapper";
import ModalWrapper from "../components/ModalWrapper";

interface IAboutProps {
  rerenderCallback: () => void;
}

const About = ({ rerenderCallback }: IAboutProps) => {
  // MATOMO TRACKING

  // * About Data states
  const [aboutLoaded, setAboutLoaded] = useState(false);
  const [aboutResponse, setAboutResponse] = useState<IInitialResponse>();
  const [aboutError, setAboutError] = useState();

  // * Load about
  useEffect(() => {
    let { url, body } = API.getContent("info");

    // * Dont load again, use local storage
    if (!checkFetch("about")) {
      const localData = getLocalData("about");
      if (localData !== null) {
        setAboutResponse(localData);
        setAboutLoaded(true);
      } else {
        //
      }
    } else {
      if (!aboutLoaded) {
        fetch(url, body)
          .then((res) => res.json())
          .then(
            (result) => {
              setAboutResponse(result);
              setAboutLoaded(true);
              setLocalData("about", result);
              setFetchTimeStamp("about");
            },
            (error) => {
              setAboutLoaded(true);
              setAboutError(error);
            }
          );
      }
    }
  }, [aboutLoaded, aboutError]);

  const [language, setLanguage] = useState(getLocalStorage("lang"));

  let accordionItems: IAccordionItemProps[] = new Array<IAccordionItemProps>();
  if (aboutResponse?.content !== null && aboutResponse?.content !== undefined) {
    aboutResponse.content.faq.map((item: any) =>
      accordionItems.push(
        language === "de"
          ? {
              title: item.deutsch.titel,
              content: item.deutsch.beschreibung,
            }
          : { title: item.englisch.titel, content: item.englisch.beschreibung }
      )
    );
  }

  accordionItems.push({
    title:
      getLocalData("initial")?.content.deinSavecode[
        language === "de" ? "deutsch" : "englisch"
      ],
    content: `<b>${getLocalStorage("savecode")}</b> <br> 
      ${
        getLocalData("initial")?.content.savecodehinweis[
          language === "de" ? "deutsch" : "englisch"
        ]
      }`,
  });

  accordionItems.push({
    title: language === "de" ? "Impressum" : "Legal Notice",
    content: getLocalData("initial")?.content.impressum[getLanguageString()],
  });

  accordionItems.push({
    title: language === "de" ? "Nutzungsbedingungen" : "Terms of use",
    content: getLocalData("initial")?.content.toc[getLanguageString()],
  });

  function handleLanguageSwitch(lang: string) {
    setLocalStorage("lang", lang);
    setLanguage(lang);
    rerenderCallback();
  }

  useEffect(() => {
    // window?._paq.push(["trackPageView", window?.location.href]);
  });

  return (
    <main className="about">
      <ScreenWrapper centered>
        <Switch
          actionLeft={() => handleLanguageSwitch("de")}
          actionRight={() => handleLanguageSwitch("en")}
          textLeft="Deutsch"
          textRight="English"
          active={getLocalStorage("lang") === "de" ? "left" : "right"}
        />
        <Box mt="4">
          {accordionItems !== undefined ? (
            <Accordion items={accordionItems} />
          ) : (
            <></>
          )}
        </Box>
        <Box textAlign="center" mt="8" mb="128px">
          <Typo type="label">
            {
              getLocalData("initial")?.content.unterstuetztdurch[
                language === "de" ? "deutsch" : "englisch"
              ]
            }
          </Typo>
          <VStack maxW="xl" m="auto" p="8" spacing="4">
            <Skeleton isLoaded={aboutLoaded}>
              {aboutResponse?.content !== null &&
              aboutResponse?.content !== undefined ? (
                aboutResponse.content.sponsoren.map(
                  (item: any, index: number) => {
                    return (
                      <Image
                        key={`image_${index.toString()}`}
                        src={item.sponsor?.sourceUrl}
                      />
                    );
                  }
                )
              ) : (
                <></>
              )}
            </Skeleton>
          </VStack>
        </Box>
      </ScreenWrapper>
    </main>
  );
};

export default About;
