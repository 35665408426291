import * as React from "react";

import { Badge as BadgeC, Icon } from "@chakra-ui/react";
import { getLanguageString } from "../functions/helpers";

export interface IBadgeProps {
  type?:
    | "wochentipp"
    | "darmstadt"
    | "studierendenwerk"
    | "news"
    | "semestertipp"
    | "notRead"
    | "new"
    | "claimed";
  label?: string;
}

const Badge = ({ type, label }: IBadgeProps): JSX.Element => {
  let c = "gray.600";
  switch (type) {
    case "wochentipp":
      c = "brand.900";
      break;
    case "news":
      c = "blue.800";
      break;
    case "semestertipp":
      c = "red.900";
      break;
    case "notRead":
      c = "brand.900";
      break;
    case "claimed":
      c = "gray.500";
      break;
    case "new":
      c = "blue.900";
      break;
  }

  return (
    <BadgeC
      fontWeight="medium"
      letterSpacing="0.1em"
      color="white"
      w="fit-content"
      background={c}
      borderRadius="4px"
      px="8px"
      fontSize={{ base: "xs", md: "sm" }}
    >
      {type === "notRead" ? (
        "!"
      ) : type === "claimed" ? (
        <>
          {getLanguageString() === "deutsch" ? "Gespeichert" : "Claimed"}
          <Icon mb="1" ml="1">
            <title id="myBonbonsTitle">
              Du hast dieses Bonbon schon gespeichert | You already saved this
              bonbon
            </title>
            <svg
              aria-labelledby="myBonbonsTitle"
              stroke="white"
              fill="white"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
          </Icon>
        </>
      ) : label ? (
        label
      ) : (
        type
      )}
    </BadgeC>
  );
};

export default Badge;
