const DropdownIcon = () => {
  return (
    <svg
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.5 6L0.468911 0.749999L6.53109 0.75L3.5 6Z" fill="#4F4F4F" />
    </svg>
  );
};

export default DropdownIcon;
