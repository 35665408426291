import * as React from "react";
import { Box, Image, Icon, Flex, Spacer, Heading } from "@chakra-ui/react";
import { Link, useHistory, useLocation } from "react-router-dom";
import CloseButton from "./CloseButton";

type HeaderBarProps = {
  title: string;
  dontShowInfo?: boolean;
};

function HeaderBar({ title, dontShowInfo }: HeaderBarProps) {
  let location = useLocation();
  let history = useHistory();

  return (
    <Box
      position="fixed"
      zIndex="100"
      w="full"
      background="white"
      pt="8"
      pb="2"
      shadow="lg"
      transition="all 150ms"
    >
      <header>
        <Box maxW={{ base: "full", md: "1024px" }} m={{ md: "auto" }}>
          <Flex>
            <Box pl="4">
              <Link to="/overview">
                <Image
                  alt="Zurück zur Übersicht | Back to overview"
                  src="logo192.png"
                  w={{ base: "32px", md: "42px" }}
                  h={{ base: "32px", md: "42px" }}
                />
              </Link>
            </Box>
            <Spacer />
            {location.pathname === "/about" ? (
              <Box pr="4">
                <CloseButton onClick={history.goBack} />
              </Box>
            ) : (
              !dontShowInfo && (
                <Link to="/about" aria-label="Link zu weiteren Information">
                  <Box pr="4">
                    <Icon
                      mt="1"
                      h={{ base: "24px", md: "32px" }}
                      w={{ base: "24px", md: "32px" }}
                    >
                      <title id="aboutTitle">
                        Link zu weiteren Informationen
                      </title>
                      <svg
                        aria-labelledby="aboutTitle"
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </Icon>
                  </Box>
                </Link>
              )
            )}
          </Flex>
          <Heading
            as="h1"
            mt="2"
            fontWeight="bold"
            fontSize={{ base: "xl", md: "2xl" }}
            pl="4"
            pr="4"
          >
            {title}
          </Heading>
        </Box>
      </header>
    </Box>
  );
}

export default HeaderBar;
