import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as API from "../functions/useAPI";

const Analytics = (props: any) => {
  // * Get analytics
  const [loaded, setLoaded] = useState(false);
  const [response, setResponse] = useState("");

  let { password } = useParams<{ password?: string }>();

  useEffect(() => {
    if (password === undefined || password === null) {
      password = "";
    }
    const { url, body } = API.getAnalytics(password);
    if (!loaded) {
      fetch(url, body)
        .then((res) => res.json())
        .then(
          (result) => {
            setLoaded(true);
            // console.log(result);
            setResponse(JSON.stringify(result));
          },
          (error) => {
            setLoaded(true);
            setResponse("Analytics können nicht abgerufen werden.");
          }
        );
    }
  }, [loaded]);

  return <>Analytics: {response}</>;
};

export default Analytics;
