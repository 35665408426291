import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import App from "./App";

const colors = {
  brand: {
    900: "#875694",
    800: "#694474",
    700: "#2a69ac",
  },
  blue: {
    // 900: "#77AFDD", old -> low contrast
    900: "#2D77B4",
    800: "#256193",
    // 800: "#1b74a0",
  },
  red: {
    900: "#CD1013",
    800: "#AB0D0F",
  },
  purple: {
    900: "#875694",
    800: "#694474",
  },
};

const fonts = {
  heading: "Carlito, sans-serif",
  body: "Carlito, sans-serif",
  mono: "Carlito, sans-serif",
};

const theme = extendTheme({ colors, fonts });

if (process.env.NODE_ENV !== "production") {
  import("react-axe").then((axe) => {
    axe.default(React, ReactDOM, 1000);
    ReactDOM.render(
      <React.StrictMode>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  });
} else {
  ReactDOM.render(
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
