import * as React from "react";

import { Box } from "@chakra-ui/react";

interface IScreenWrapperProps {
  centered?: boolean;
}

const ScreenWrapper = (
  props: React.PropsWithChildren<IScreenWrapperProps>
): JSX.Element => {
  return (
    <Box background="white" pt="4">
      <Box m="4" minH="100%" mt={{ base: "116px", md: "140px" }} pb="80px">
        {props.centered ? (
          <Box
            mb="128px"
            m={{ md: "auto" }}
            maxW={{ base: "full", md: "700px" }}
          >
            {props.children}
          </Box>
        ) : (
          props.children
        )}
      </Box>
    </Box>
  );
};

export default ScreenWrapper;
