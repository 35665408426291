import * as React from "react";
import { useState } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Flex,
  Text,
  Button as ButtonC,
  useToast,
  Divider,
} from "@chakra-ui/react";
import Button from "../components/Button";
import Bonbon from "../components/Bonbon";
import QRScan from "../components/QRScan";
import { setLocalStorage, getLocalStorage } from "../functions/helpers";
import * as API from "../functions/useAPI";

function Scanner() {
  // Toast
  const toast = useToast();

  // Login
  const [loggedIn, setloggedIn] = useState(false);

  // Password Input Field
  const [pwValue, setPwValue] = useState("");

  // Bonbon Content
  const [BonbonContent, setBonbonContent] = useState<any>();

  // Password
  const [show, setShow] = React.useState(false);
  const handleShowClick = () => setShow(!show);

  // Scanning
  const [checking, setChecking] = useState(false);
  const [enteredCode, setEnteredCode] = useState("");

  React.useEffect(() => {
    if (
      getLocalStorage("vLoggedIn") === "true" &&
      getLocalStorage("vtoken") !== null
    ) {
      setloggedIn(true);
    }
  }, []);

  function handleLogin() {
    const { url, body } = API.authenticate("scanner", pwValue);
    fetch(url, body)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.error) {
            toast({
              title: "Es ist ein Fehler aufgetreten :(",
              description:
                "Versuchen Sie es erneut oder überprüfen Sie Ihr Password",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            setloggedIn(result.auth);
            setLocalStorage("vtoken", pwValue);
            setLocalStorage("vLoggedIn", result.auth);
          }
        },
        (error) => {
          toast({
            title: "Es ist ein Fehler aufgetreten :(",
            description:
              "Versuchen Sie es erneut oder überprüfen Sie Ihr Password",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
  }

  function handleCodeCheck(data?: any) {
    let veranstaltungsID = enteredCode.split("_")[0];
    if (data) {
      veranstaltungsID = data.split("_")[0];
    }

    const vToken: string = getLocalStorage("vtoken")!.toString();

    const { url, body } = API.getScannedContent(veranstaltungsID, vToken);
    fetch(url, body)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.error) {
            setChecking(false);
            toast({
              title: "Es ist ein Fehler aufgetreten :(",
              description:
                "Der QR Code ist nicht korrekt, versuchen Sie es erneut.",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            setBonbonContent(result.content[Object.keys(result.content)[0]]);
            setChecking(true);
          }
        },
        (error) => {
          setChecking(false);
          toast({
            title: "Es ist ein Fehler aufgetreten :(",
            description:
              "Der QR Code ist nicht korrekt, versuchen Sie es erneut.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
  }

  function handleCodeUsage() {
    const veranstaltungsID = enteredCode.split("_")[0];
    const userCode = enteredCode.split("_")[1];
    const vToken: string = getLocalStorage("vtoken")!.toString();

    const { url, body } = API.getUseBonbon(userCode, vToken, veranstaltungsID);
    fetch(url, body)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.auth === true && result.success === true) {
            toast({
              title: "Code ist gültig!",
              description: "Der Code war gültig und ist nun validiert.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            setEnteredCode("");
            setChecking(false);
          } else {
            toast({
              title: "Das Bonbon ist leider nicht gültig :(",
              description:
                "Versuchen Sie es erneut und überprüfen Sie das Bonbon",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            setEnteredCode("");
            setChecking(false);
          }
        },
        (error) => {
          setEnteredCode("");
          setChecking(false);
          toast({
            title: "Es ist ein Fehler aufgetreten :(",
            description: "Versuchen Sie es erneut",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
  }

  function handleCancel() {
    setChecking(false);
    setEnteredCode("");
  }

  return (
    <main className="Details">
      <Box
        pr="4"
        pl="4"
        pt="128px"
        mb="80px"
        m={{ md: "auto" }}
        maxW={{ base: "full", md: "700px" }}
      >
        {loggedIn ? (
          checking ? (
            // Checking
            <Box>
              <Bonbon
                preview={false}
                scanner={false}
                date={BonbonContent.bonbon.ablaufdatum}
                title={BonbonContent.tipps.inhalt.deutsch.titel}
                description={BonbonContent.tipps.inhalt.deutsch.beschreibung}
                type={BonbonContent.tipps.tippTyp}
              />
              <Box mt="4">
                <Button
                  small
                  onClick={() => {
                    handleCodeUsage();
                  }}
                >
                  Validieren
                </Button>
              </Box>
              <Box mt="4">
                <Button
                  small
                  secondary
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  Abbrechen
                </Button>
              </Box>
            </Box>
          ) : (
            // Scanning
            <>
              <QRScan
                openString="QR Code scannen & Überprüfen"
                successString="Erfolgreich gescannt."
                callback={(data) => {
                  setEnteredCode(data);
                  handleCodeCheck(data);
                }}
              />

              <Divider mt="32px" mb="32px" />

              <Text mt="32px">Oder Code manuell eingeben:</Text>
              <Box mt="4">
                <Input
                  value={enteredCode}
                  onChange={(event) => {
                    setEnteredCode(event.target.value);
                  }}
                  placeholder="Code eingeben..."
                />
                <Box mt="4">
                  <Button
                    small
                    onClick={() => {
                      handleCodeCheck();
                    }}
                  >
                    Überprüfen
                  </Button>
                </Box>
              </Box>
            </>
          )
        ) : (
          // Login
          <Flex mt="60px" align="center">
            <Box w="full">
              Veranstalter Passwort
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  value={pwValue}
                  onChange={(event) => {
                    setPwValue(event.target.value);
                  }}
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                />
                <InputRightElement width="4.5rem">
                  <ButtonC h="1.75rem" size="sm" onClick={handleShowClick}>
                    {show ? "Hide" : "Show"}
                  </ButtonC>
                </InputRightElement>
              </InputGroup>
              <Box mt="32px">
                <Button
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  Einloggen
                </Button>
              </Box>
            </Box>
          </Flex>
        )}
      </Box>
    </main>
  );
}

export default Scanner;
