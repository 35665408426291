import * as React from "react";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Button,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  FormControl,
  FormLabel,
  FormHelperText,
  useToast,
} from "@chakra-ui/react";
import { useClipboard } from "@chakra-ui/hooks";
import * as API from "../functions/useAPI";

function generateCodes(count: number) {
  let codes = "";
  for (let i = 0; i < count; i++) {
    if (i === count - 1) {
      codes += uuid();
    } else {
      codes += uuid() + "\n";
    }
  }
  return codes;
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

function exportToCsv(filename: string, codes: string) {
  const csvContent = codes.toString();

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function CodeGenerator() {
  // Codes
  const [codes, setCodes] = useState("");

  // Amount Input Field
  const [value, setValue] = useState(1);

  // Password Input Field
  const [pwValue, setPwValue] = useState("");

  // Generate Table Response
  const [CodeGeneratorLoaded, setCodeGeneratorLoaded] = useState(false);
  const [tableName, settableName] = useState("");
  const [CodeGeneratorError, setCodeGeneratorError] = useState();
  const [handleGenerationLoading, setHandleGenerationLoading] = useState(false);

  // Clipboard
  const { hasCopied, onCopy } = useClipboard(tableName);

  // Password
  const [showPW, setShowPW] = React.useState(false);

  // Toast
  const toast = useToast();

  const handleGeneration = (c: string) => {
    setCodes(c);
    // Codes to String
    let codesArrayString = "[";
    c.split("\n").map((item, index) => {
      if (c.split("\n").length - 1 === index) {
        codesArrayString = `${codesArrayString}"${item}"`;
        codesArrayString = `${codesArrayString}]`;
      } else {
        codesArrayString = `${codesArrayString}"${item}",`;
      }
    });

    const { url, body } = API.save("bonbonset", codesArrayString, pwValue);
    fetch(url, body)
      .then((res) => res.json())
      .then(
        (result) => {
          setCodeGeneratorLoaded(true);
          setHandleGenerationLoading(false);
          if (result.error) {
            toast({
              title: "Es ist ein Fehler aufgetreten :(",
              description:
                "Versuchen Sie es erneut oder überprüfen Sie Ihr Password",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            settableName(result.tableName);
          }
        },
        (error) => {
          setCodeGeneratorError(error);
          toast({
            title: "Es ist ein Fehler aufgetreten :(",
            description:
              "Versuchen Sie es erneut oder überprüfen Sie Ihr Password",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setHandleGenerationLoading(false);
        }
      );
  };

  return (
    <main className="CodeGenerator">
      <Box pt="128px" pb="128px" pr="4" pl="4">
        <FormControl id="Anzahl">
          <FormLabel>Wie viele Codes sollen generiert werden?</FormLabel>
          <NumberInput
            defaultValue={500}
            min={1}
            max={20000}
            clampValueOnBlur={true}
            keepWithinRange={true}
            value={value}
            onChange={(valueAsString: string, valueAsNumber: number) => {
              if (isNaN(valueAsNumber)) {
                setValue(1);
              } else {
                setValue(valueAsNumber);
              }
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormHelperText>
            Es können maximal 20000 Codes generiert werden.
          </FormHelperText>
        </FormControl>

        <FormControl id="Password" mt="32px">
          <FormLabel>Geben Sie das CodeGenerator-Passwort ein</FormLabel>
          <InputGroup size="md">
            <Input
              value={pwValue}
              pr="4.5rem"
              type={showPW ? "text" : "password"}
              placeholder="Enter password"
              onChange={(event) => {
                setPwValue(event.target.value);
              }}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={() => setShowPW(!showPW)}>
                {showPW ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <HStack mb="8" mt="2">
          <Button
            isLoading={handleGenerationLoading}
            loadingText="Einen Augenblick"
            onClick={() => {
              setHandleGenerationLoading(true);
              handleGeneration(generateCodes(value));
            }}
            colorScheme="teal"
            size="md"
          >
            Codes Generieren
          </Button>
        </HStack>

        {tableName && CodeGeneratorLoaded ? (
          <FormControl id="Anzahl">
            <FormLabel>Tabellenname:</FormLabel>

            <InputGroup size="md">
              <Input
                value={tableName}
                isReadOnly
                placeholder="Es ist ein Fehler aufgetreten"
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={onCopy}>
                  {hasCopied ? "done" : "copy"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        ) : (
          ""
        )}

        <Button
          mt="32px"
          onClick={() => exportToCsv(`${tableName}.csv`, codes)}
          mb="4"
        >
          Download CSV
        </Button>
      </Box>
    </main>
  );
}

export default CodeGenerator;
