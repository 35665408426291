import React, { useEffect, useState } from "react";
import { Box, HStack, Icon, Badge } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import {
  checkFetch,
  getLanguageString,
  getLocalData,
  setLocalData,
} from "../functions/helpers";
import { IInitialResponse } from "../Types";
import * as API from "../functions/useAPI";
export interface ITabBarProps {
  links: ILinkProps[];
}

export interface ILinkProps {
  title: string;
  link: string;
  icon?: string;
}

const iconOverview = (
  <>
    <title id="overviewTitle">Link zur Übersicht | Link to overview</title>
    <svg
      aria-labelledby="overviewTitle"
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
      />
    </svg>
  </>
);

const iconMyBonbons = (
  <>
    <title id="myBonbonsTitle">
      Link zu deinen gespeicherten Bonbons | Link to your saved bonbons
    </title>
    <svg
      aria-labelledby="myBonbonsTitle"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
      />
    </svg>
  </>
);

const iconNews = (
  <>
    <title id="newsTitle">Link zu News | Link to news</title>
    <svg
      aria-labelledby="newsTitle"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
      />
    </svg>
  </>
);

const TabBar = ({ links }: ITabBarProps): JSX.Element => {
  const location = useLocation();

  // * Map title to language
  let lang = getLanguageString();

  // * News Data states
  const [newsLoaded, setNewsLoaded] = useState(false);
  const [newsResponse, setNewsResponse] = useState<IInitialResponse>();
  const [newsError, setNewsError] = useState();

  // * Load News
  useEffect(() => {
    let { url, body } = API.getContent("news");
    // * Dont load again, use local storage
    if (!checkFetch("news")) {
      const localData = getLocalData("news");
      if (localData !== null) {
        setNewsResponse(localData);
        setNewsLoaded(true);
      } else {
        //
      }
    } else {
      // * Fetch if not loaded
      if (!newsLoaded) {
        fetch(url, body)
          .then((res) => res.json())
          .then(
            (result) => {
              setNewsResponse(result);
              setNewsLoaded(true);
              setLocalData("news", result);
            },
            (error) => {
              setNewsLoaded(true);
              setNewsError(error);
              console.log("Error loading data:", newsError);
            }
          );
      }
    }
  }, [newsLoaded, newsError]);

  function getTitle(t: string) {
    if (
      getLocalData("initial")?.content !== undefined &&
      getLocalData("initial")?.content !== null
    )
      switch (t) {
        case "Übersicht":
          return getLocalData("initial")?.content.uebersicht[lang];
        case "Meine Bonbons":
          return getLocalData("initial")?.content.meinebonbons[lang];
        case "News":
          return getLocalData("initial")?.content.news[lang];
      }
    else {
      return t;
    }
  }

  function checkIfNewNews(newsResponse: any) {
    if (newsResponse) {
      const lastReadNews = new Date(newsResponse.lastReadNews);
      let latestNews = new Date(0);

      // Get Latest News
      newsResponse?.content !== null &&
        newsResponse?.content !== undefined &&
        newsResponse?.content.forEach((element: any) => {
          const date = new Date(element.news.datum);
          if (date > latestNews) {
            latestNews = date;
          }
        });

      if (latestNews > lastReadNews) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <Box
      position="fixed"
      bottom="0"
      background="white"
      left={{ base: "0", md: "50%" }}
      ml={{ base: "0", md: "-385px" }}
      maxW={{ base: "full", md: "770px" }}
      transform={{ base: "scale(1)", md: "scale(1.2)" }}
      w="full"
      shadow="dark-lg"
      rounded="xl"
      zIndex="100"
    >
      <footer>
        <nav className="TabBar">
          <HStack
            spacing="0"
            textAlign="center"
            fontSize="1em"
            fontWeight="bold"
          >
            {links.map((l, i) => {
              return (
                <Box
                  key={l.title}
                  w={`${100 / links.length}%`}
                  // w="33%"
                  borderTop="2px solid"
                  roundedTopLeft={i === 0 ? "xl" : "none"}
                  roundedTopRight={i === 2 ? "xl" : "none"}
                  borderTopColor={
                    location.pathname === l.link ? "brand.900" : "white"
                  }
                  color={location.pathname === l.link ? "brand.900" : "black"}
                >
                  <Link to={l.link}>
                    <Box pt="4" pb="4">
                      <Box position="relative">
                        {i === 2 && checkIfNewNews(newsResponse) ? (
                          <Badge
                            position="absolute"
                            top="-3px"
                            left="49.9%"
                            px="8px"
                            bg="brand.900"
                            color="white"
                            borderRadius="8px"
                          >
                            !
                          </Badge>
                        ) : (
                          ""
                        )}
                        <Icon
                          w="100%"
                          transform={i === 0 ? "rotate(90deg)" : ""}
                          boxSize="24px"
                        >
                          {i === 0
                            ? iconOverview
                            : i === 1
                            ? iconMyBonbons
                            : iconNews}
                        </Icon>
                      </Box>
                      {getTitle(l.title)}
                    </Box>
                  </Link>
                </Box>
              );
            })}
          </HStack>
        </nav>
      </footer>
    </Box>
  );
};

export default TabBar;
