import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import BonbonCard from "../components/BonbonCard";
import ScreenWrapper from "../components/ScreenWrapper";
import { getLanguageString, getLocalData } from "../functions/helpers";
import Button from "../components/Button";
import { Box } from "@chakra-ui/react";

const Details = (props: any) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    // window?._paq.push(["trackPageView", window?.location.href]);
  });

  return (
    <main className="Details">
      <ScreenWrapper centered>
        <BonbonCard
          slug={props.data.slug}
          date={props.data.date}
          title={props.data.title}
          type={props.data.type}
          description={props.data.description}
          image={props.data.image}
          bonbon={props.data.bonbon}
          badges={props.data.badges}
          details
        />
        <Box mt="4" mb={{ base: "0", md: "40px" }}>
          <Button
            color={props.data.type === "news" ? "blue.800" : undefined}
            small
            onClick={() => history.goBack()}
          >
            {getLocalData("initial")?.content.zurueck[getLanguageString()]}
          </Button>
        </Box>
      </ScreenWrapper>
    </main>
  );
};

export default Details;
