/* eslint-disable no-useless-escape */
import { IInitialResponse } from "../Types";

export function createMarkup(s: string) {
  return {
    __html: s,
  };
}

export function generateSlug(s: string) {
  if (!!s) {
    return s.replaceAll(" ", "-");
  } else {
    return s;
  }
}

export function setLocalStorage(key: string, value: string) {
  if (window.localStorage) {
    window.localStorage.setItem(key, value);
  } else {
    console.log("Local Storage not supported.");
  }
}

export function getLocalStorage(key: string) {
  if (window.localStorage) {
    return window.localStorage.getItem(key);
  }
}

export function getLanguageString() {
  let l = getLocalStorage("lang");
  if (l === null) {
    return "deutsch";
  }
  if (l === "de") {
    return "deutsch";
  } else {
    return "englisch";
  }
}

// * True if fetching again, false if not yet
export function checkFetch(type: string) {
  // * Time to refresh fetching in seconds
  const timeInterval = 180;
  const lastFetch = getLocalStorage(`${type}_fetch_timestamp`);
  if (lastFetch !== undefined && lastFetch !== null) {
    let parsed = JSON.parse(lastFetch);
    if (parsed < Date.now() - timeInterval * 1000) {
      // setLocalStorage(`${type}_fetch_timestamp`, Date.now().toString());
      return true;
    } else {
      return false;
    }
  } else {
    // setLocalStorage(`${type}_fetch_timestamp`, Date.now().toString());
    return true;
  }
}

export function setFetchTimeStamp(type: string) {
  setLocalStorage(`${type}_fetch_timestamp`, Date.now().toString());
}

export function resetFetchTimeStamp(type: string) {
  if (window.localStorage) {
    window.localStorage.removeItem(`${type}_fetch_timestamp`);
  }
}

// * Returns local data as object
export function getLocalData(type: string): IInitialResponse | null {
  const localData = getLocalStorage(`${type}_fetchedData`);
  if (localData !== undefined && localData !== null) {
    const parsed = JSON.parse(localData);
    return parsed;
  } else {
    return null;
  }
}

// * Saves data to local storage
export function setLocalData(type: string, data: unknown) {
  setLocalStorage(`${type}_fetchedData`, JSON.stringify(data));
}

export function isMobile() {
  var isMobile = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }
  return isMobile;
}

export function fixDate(
  date: string,
  type: "news" | "semestertipp" | "wochentipp"
) {
  if (date === null) {
    return "";
  }
  let uhr = getLanguageString() === "deutsch" ? " Uhr" : " ";
  // * Safari / iOS fix
  date = date.replace(/-/g, "/");
  const d = new Date(date).toLocaleDateString("de-DE", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const t =
    new Date(date).toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    }) + uhr;
  if (type === "news") {
    // News
    return `${d}`;
  } else if (type === "semestertipp") {
    // Semestertipp
    return getLanguageString() === "deutsch" ? `Bis zum ${d}` : `Until ${d}`;
  } else {
    // Wochentipp
    if (t.includes("00:00")) {
      return `${d}`;
    } else {
      return `${d} ${t}`;
    }
  }
}

export function dateInPast(date: string) {
  if (new Date(date) <= new Date()) {
    return true;
  }
  return false;
}

export function checkLoggedIn() {
  const localSaveCode = getLocalStorage("savecode");
  if (localSaveCode !== null && localSaveCode !== undefined) {
    return true;
  } else {
    return false;
  }
}

interface ITippsLocalStorage {
  bonbon: Object;
  databaseID: number;
  id: string;
  tipps: Object;
}

export function checkIsBonbonNew(bonbonID: string) {
  // * Get NEW fetched tipps
  // const newTipps = getLocalData("tipps")?.content;

  // * Get LAST TIME fetched tipps
  const oldTipps: ITippsLocalStorage = getLocalData("tipps_old")?.content;

  const oldIDs = [""];

  if (oldTipps === null || oldTipps === undefined) {
    return false;
  }

  for (let [key, value] of Object.entries(oldTipps)) {
    oldIDs.push(value.id);
  }

  // * COMPARE
  if (oldIDs.includes(bonbonID)) {
    return false;
  } else {
    return true;
  }
}

const umlautMap: any = {
  "\u00dc": "UE",
  "\u00c4": "AE",
  "\u00d6": "OE",
  "\u00fc": "ue",
  "\u00e4": "ae",
  "\u00f6": "oe",
  "\u00df": "ss",
  "\uFFFD": "",
};

const umlaute = ["Ä", "Ü", "Ö", "ß", "ä", "ü", "ö", "ý", "�"];

export function replaceUmlaute(str: string) {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(
      new RegExp("[" + Object.keys(umlautMap).join("|") + "]", "g"),
      (a) => umlautMap[a]
    );
}

export function checkUmlaute(str: string) {
  let hasUmlaut = false;
  for (let char of str) {
    if (umlaute.indexOf(char) >= 0) {
      hasUmlaut = true;
    }
  }
  return hasUmlaut;
}
