import * as React from "react";
import { Circle, Image, Tooltip, WrapItem } from "@chakra-ui/react";
import { getLanguageString } from "../functions/helpers";

export interface IAccessibilityIconProps {
  type:
    | "Kosten"
    | "Kostenfrei"
    | "Große Gruppen"
    | "Mittlere Gruppen"
    | "Kleine Gruppen"
    | "Bauliche Barrierefreiheit"
    | "Leichte Sprache"
    | "Hilfsmittel für Hörbeeinträchtigte Menschen"
    | "Hilfsmittel für blinde/sehbeeinträchtigte Menschen"
    | "Gebärdensprache"
    | "Parkplätze für beeinträchtigte Menschen"
    | string;
}

const AccessibilityIcon = ({ type }: IAccessibilityIconProps): JSX.Element => {
  let imagePath = "";
  let engDescription = "";

  switch (type) {
    case "Kosten":
      imagePath = "kosten.png";
      engDescription = "Costs";
      break;
    case "Kostenfrei":
      imagePath = "kostenlos.png";
      engDescription = "Free";
      break;
    case "Große Gruppen":
      imagePath = "gruppe_gross.png";
      engDescription = "Accessible for large sized groups";
      break;
    case "Mittlere Gruppen":
      imagePath = "gruppe_mittel.png";
      engDescription = "Accessible for medium sized groups";
      break;
    case "Kleine Gruppen":
      imagePath = "gruppe_klein.png";
      engDescription = "Accessible for small sized groups";
      break;
    case "Bauliche Barrierefreiheit":
      imagePath = "barrierefreiheit.png";
      engDescription = "Structurable accesibility";
      break;
    case "Leichte Sprache":
      imagePath = "leichte_sprache.png";
      engDescription = "Easy language";
      break;
    case "Hilfsmittel für Hörbeeinträchtigte Menschen":
      imagePath = "hörbeeinträchtigt.png";
      engDescription = "Aids for hearing impaired people";
      break;
    case "Hilfsmittel für blinde/sehbeeinträchtigte Menschen":
      imagePath = "sehbeeinträchtigt.png";
      engDescription = "Aids for blind/visually impaired people";
      break;
    case "Gebärdensprache":
      imagePath = "gebärdensprache.png";
      engDescription = "Sign language";
      break;
    case "Parkplätze für beeinträchtigte Menschen":
      imagePath = "parken.png";
      engDescription = "Parking spaces for impaired people";
      break;
    default:
      break;
  }

  return (
    <WrapItem>
      <Tooltip
        label={getLanguageString() === "deutsch" ? type : engDescription}
        aria-label={getLanguageString() === "deutsch" ? type : engDescription}
      >
        <Circle tabIndex={0} overflow="hidden" size="32px" background="#2699D1">
          <Image
            alt={getLanguageString() === "deutsch" ? type : engDescription}
            src={"accessibility_icons/" + imagePath}
          />
        </Circle>
      </Tooltip>
    </WrapItem>
  );
};

export default AccessibilityIcon;
