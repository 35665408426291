import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Button as ButtonC,
  Checkbox,
  HStack,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { useClipboard } from "@chakra-ui/hooks";
import { Link, useHistory } from "react-router-dom";
import Typo from "../components/Typo";
import Button from "../components/Button";
import {
  getLanguageString,
  getLocalData,
  setLocalStorage,
} from "../functions/helpers";
import ScreenWrapper from "../components/ScreenWrapper";
import * as API from "../functions/useAPI";
import ModalWrapper from "../components/ModalWrapper";

const uuid = uuidv4();

interface INewCodeProps {
  loginCallback: () => void;
}

const NewCode = ({ loginCallback }: INewCodeProps) => {
  const { hasCopied, onCopy } = useClipboard(uuid);
  React.useEffect(() => {
    // window?._paq.push(["trackPageView", window?.location.href]);
  });

  const history = useHistory();

  // * TOS Checkbox
  const [tosAgreed, setTosAgreed] = useState(false);

  // * Save Code states
  const [codeSaved, setCodeSaved] = useState(false);
  const [error, setError] = useState();

  function saveCode() {
    // * Set non vip
    setLocalStorage("vip", "false");

    // * Check if valid vip
    const { url, body } = API.authenticate("newsavecode", uuid);
    fetch(url, body)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("Code saved response: ", result);
          // * check result
          if (result.auth) {
            console.log("Success");
            setCodeSaved(true);
          }
        },
        (error) => {
          console.log("Error", error);
          setCodeSaved(false);
          setError(error);
        }
      );
    if (error) {
      console.log("Error saving code:", error);
    }
  }

  useEffect(() => {
    if (codeSaved) {
      // * Redirect if code saved
      setLocalStorage("savecode", uuid);
      loginCallback();
      history.push("/overview");
    }
  }, [codeSaved, history]);

  return (
    <main className="NewCode">
      <ScreenWrapper centered>
        <Typo type="headline">
          {getLocalData("initial")?.content.deinSavecode[getLanguageString()]}
        </Typo>
        <Flex mb="4">
          <Input value={uuid} isReadOnly placeholder="Welcome" />
          <ButtonC onClick={onCopy} ml={2}>
            {
              // TODO: language
            }
            {hasCopied
              ? getLanguageString() === "deutsch"
                ? "Kopiert"
                : "Copied"
              : getLanguageString() === "deutsch"
              ? "Kopieren"
              : "Copy"}
          </ButtonC>
        </Flex>
        <Typo type="paragraph">
          {
            getLocalData("initial")?.content.savecodehinweis[
              getLanguageString()
            ]
          }
        </Typo>
        <Box mt="8" mb="4">
          <HStack>
            <Checkbox onChange={(e) => setTosAgreed(e.target.checked)} />
            <ModalWrapper
              content={
                getLocalData("initial")?.content.toc[getLanguageString()]
              }
              link={
                getLocalData("initial")?.content.nutzungsbedingungen[
                  getLanguageString()
                ]
              }
              title={
                getLanguageString() === "deutsch"
                  ? "Nutzungsbedingungen"
                  : "Terms of use"
              }
            />
          </HStack>
          <Box mt="4">
            {
              // TODO: set logged in to code
            }
            <Button
              small
              disabled={!tosAgreed}
              onClick={() => {
                saveCode();
              }}
            >
              Ja, der Code wurde notiert.
            </Button>
          </Box>
        </Box>
      </ScreenWrapper>
    </main>
  );
};

export default NewCode;
