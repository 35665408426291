import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HeaderBar from "./components/HeaderBar";
import TabBar, { ILinkProps } from "./components/TabBar";
import Splash from "./screens/Splash";
import Login from "./screens/Login";
import Overview from "./screens/Overview";
import MyBonbons from "./screens/MyBonbons";
import News from "./screens/News";
import CodeGenerator from "./screens/CodeGenerator";
import Details from "./screens/Details";
import About from "./screens/About";
import NewCode from "./screens/NewCode";
import Scanner from "./screens/Scanner";
import SaveCode from "./screens/SaveCode";
import Analytics from "./screens/Analytics";
import {
  checkFetch,
  getLanguageString,
  getLocalData,
  getLocalStorage,
  isMobile,
  setFetchTimeStamp,
  setLocalData,
  checkLoggedIn,
} from "./functions/helpers";
import BonbonDetails from "./screens/BonbonDetails";
import * as API from "./functions/useAPI";
import { IInitialResponse } from "./Types";
import { IBonbonCardProps } from "./components/BonbonCard";
import { IBonbonProps } from "./components/Bonbon";
import UploadCodes from "./screens/UploadCodes";

declare global {
  interface Window {
    _paq: any;
  }
}

// const defaultLinks = ["/overview", "/my-bonbons", "/news"];
const defaultLinks: ILinkProps[] = [
  {
    title: "Übersicht",
    link: "/overview",
  },
  { title: "Meine Bonbons", link: "/my-bonbons" },
  { title: "News", link: "/news" },
];

export default function App() {
  // TODO: ALL API CALLS
  // TODO: MAINTAINANCE MODE -> API CALL

  const [loggedIn, setLoggedIn] = useState(false);

  function checkLoggedInApp() {
    setLoggedIn(checkLoggedIn());
  }

  useEffect(() => {
    checkLoggedInApp();
  }, [loggedIn]);

  // * Initial Data states
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [initialResponse, setInitialResponse] = useState<IInitialResponse>();
  const [initialError, setInitialError] = useState();
  const [reload, setReload] = useState(false);

  // * Load initial
  useEffect(() => {
    let { url, body } = API.getContent("initial");

    // * Dont load again, use local storage
    if (!checkFetch("initial")) {
      const localData = getLocalData("initial");
      if (localData !== null) {
        setInitialResponse(localData);
        setInitialLoaded(true);
      } else {
        //
      }
    } else {
      // * Fetch if not loaded
      if (!initialLoaded) {
        fetch(url, body)
          .then((res) => res.json())
          .then(
            (result) => {
              setInitialResponse(result);
              // * Error in response?
              if (result.error) {
                const timer = setTimeout(() => {
                  // console.log("Error fetching initial... Reloading");
                  setReload(!reload);
                }, 1000);
                return () => clearTimeout(timer);
              } else {
                setInitialLoaded(true);
                setLocalData("initial", result);
                setFetchTimeStamp("initial");
              }
            },
            (error) => {
              setInitialLoaded(true);
              setInitialError(error);
              // console.log("Error loading data:", initialError);
              const timer = setTimeout(() => {
                // console.log("Error fetching initial... Reloading");
                setReload(!reload);
              }, 1000);
              return () => clearTimeout(timer);
            }
          );
      }
    }
  }, [initialLoaded, initialError, reload]);

  const [language, setLanguage] = useState(getLanguageString());

  useEffect(() => {
    if (getLanguageString() !== language) {
      setLanguage(getLanguageString());
    }
  }, [language]);

  // * Define fallback titles if initial wont load for some reason
  let titleDABonbon = "DAs Bonbon";
  let titleOverview = "Übersicht";
  let titleLogin = "Anmelden";
  let titleMyBonbons = "Meine Bonbons";
  let titleNews = "News";
  let titleAbout = "Über DAs Bonbon";

  if (
    initialResponse?.content !== null &&
    initialResponse?.content !== undefined
  ) {
    let iC = initialResponse?.content;
    titleDABonbon = iC.dasbonbon[language];
    titleOverview = iC.uebersicht[language];
    titleLogin = iC.anmelden[language];
    titleMyBonbons = iC.meinebonbons[language];
    titleNews = iC.news[language];
    titleAbout = iC.uberDasBonbon[language];
  }

  const rerenderHeaderAfterLanguageSwitch = () => {
    if (getLanguageString() !== language) {
      setLanguage(getLanguageString());
    }
  };

  return (
    <Router>
      {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
      <Switch>
        <Route exact path="/">
          {isMobile() ? <Splash /> : <Redirect to="/login" />}
        </Route>
        <Route path="/login">
          <HeaderBar dontShowInfo title={titleLogin} />
          {loggedIn ? (
            <Redirect to="/overview" />
          ) : (
            <Login loginCallback={checkLoggedInApp} />
          )}
        </Route>
        <Route path="/new-code">
          <HeaderBar dontShowInfo title={titleLogin} />
          <NewCode loginCallback={checkLoggedInApp} />
        </Route>
        <Route path="/save-code">
          <HeaderBar dontShowInfo title="Savecode" />
          <SaveCode loginCallback={checkLoggedInApp} />
        </Route>
        <Route path="/overview">
          {!loggedIn ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderBar title={titleOverview} />
              <Overview loginCallback={checkLoggedInApp} />
              <TabBar links={defaultLinks} />
            </>
          )}
        </Route>
        <Route
          path="/bonbon/:title/"
          render={({ location }) => {
            const { state } = location;
            return !loggedIn ? (
              <Redirect to="/login" />
            ) : (
              <>
                <HeaderBar title={titleDABonbon} />
                <Details data={state} />
                <TabBar links={defaultLinks} />
              </>
            );
          }}
        />
        <Route path="/my-bonbons">
          {!loggedIn ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderBar title={titleMyBonbons} />
              <MyBonbons loginCallback={checkLoggedInApp} />
              <TabBar links={defaultLinks} />
            </>
          )}
        </Route>
        <Route
          path="/bonbonDetail/:title"
          render={({ location }) => {
            const { state } = location;
            return !loggedIn ? (
              <Redirect to="/login" />
            ) : (
              <>
                <HeaderBar title={titleDABonbon} />
                <BonbonDetails data={state} />
                <TabBar links={defaultLinks} />
              </>
            );
          }}
        />
        <Route path="/news">
          {!loggedIn ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderBar title={titleNews} />
              <News loginCallback={checkLoggedInApp} />
              <TabBar links={defaultLinks} />
            </>
          )}
        </Route>
        <Route path="/about">
          {!loggedIn ? (
            <Redirect to="/login" />
          ) : (
            <>
              <HeaderBar title={titleAbout} />
              <About rerenderCallback={rerenderHeaderAfterLanguageSwitch} />
              <TabBar links={defaultLinks} />
            </>
          )}
        </Route>
        <Route path="/codeGenerator">
          <HeaderBar title="Code Generator" />
          <CodeGenerator />
        </Route>
        <Route path="/scan">
          <HeaderBar title="DAs Bonbon Scanner" />
          <Scanner />
        </Route>
        <Route
          path="/analytics/:password"
          render={({ location }) => {
            const { state } = location;
            return <Analytics data={state} />;
          }}
        />
        <Route path="/code-upload">
          <HeaderBar title="Code Upload" />
          <UploadCodes />
        </Route>
        <Route
          path="/test-bonbon"
          render={({ location }) => {
            const state: IBonbonProps = {
              date: "07.12.2022",
              description: "Test Barcode",
              title: "Test Bonbon",
              type: "wochentipp",
              limit: 200,
              claimed: 20,
              eventID: "12",
              externerCode: "kinopolis-60-bonbon-stwd",
            };
            return (
              <>
                <HeaderBar title={titleDABonbon} />
                <BonbonDetails data={state} />
                <TabBar links={defaultLinks} />
              </>
            );
          }}
        />
      </Switch>
    </Router>
  );
}
