import * as React from "react";
import { useState } from "react";
import { Box, HStack } from "@chakra-ui/react";

export interface ISwitchProps {
  active: "left" | "right";
  actionLeft?: () => void;
  actionRight?: () => void;
  textLeft: string;
  textRight: string;
}

const Switch = ({
  active,
  textLeft,
  textRight,
  actionLeft,
  actionRight,
}: ISwitchProps): JSX.Element => {
  const [sideActive, setSideActive] = useState(active);

  function handleClick(side: string) {
    if (side === "left") {
      setSideActive("left");
      if (actionLeft !== undefined) {
        actionLeft();
      }
    } else {
      setSideActive("right");
      if (actionRight !== undefined) {
        actionRight();
      }
    }
  }

  return (
    <HStack
      rounded="xl"
      border="2px solid"
      borderColor="brand.900"
      background="brand.900"
      align="left"
      h={{ base: "32px", md: "42px" }}
      lineHeight={{ base: "28px", md: "38px" }}
      overflow="hidden"
      fontWeight="bold"
      fontSize={{ base: "md", md: "xl" }}
    >
      <Box
        role="button"
        onClick={() => handleClick("left")}
        textAlign="center"
        background={sideActive === "left" ? "brand.900" : "white"}
        color={sideActive === "left" ? "white" : "brand.900"}
        w="50%"
      >
        {textLeft}
      </Box>
      <Box
        role="button"
        onClick={() => handleClick("right")}
        textAlign="center"
        background={sideActive === "right" ? "brand.900" : "white"}
        color={sideActive === "right" ? "white" : "brand.900"}
        w="50%"
      >
        {textRight}
      </Box>
    </HStack>
  );
};

export default Switch;
