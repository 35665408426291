import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Bonbon from "../components/Bonbon";
import ScreenWrapper from "../components/ScreenWrapper";
import { getLanguageString, getLocalData } from "../functions/helpers";
import { Box } from "@chakra-ui/react";
import Button from "../components/Button";

const BonbonDetails = (props: any) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // window?._paq.push(["trackPageView", window?.location.href]);
  });

  return (
    <main className="Details">
      <ScreenWrapper centered>
        <Bonbon
          title={props.data.title}
          description={props.data.description}
          date={props.data.date}
          type={props.data.type}
          limit={props.data.limit}
          claimed={props.data.claimed}
          eventID={props.data.eventID}
          linkProps={props.data.linkProps}
          externerCode={props.data.externerCode}
          customCode={props.data.customCode}
          details
        />
        <Box mt="4">
          <Button small onClick={() => history.goBack()}>
            {getLocalData("initial")?.content.zurueck[getLanguageString()]}
          </Button>
        </Box>
      </ScreenWrapper>
    </main>
  );
};

export default BonbonDetails;
