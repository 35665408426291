import { Box, Image } from "@chakra-ui/react";
import QRCode from "react-qr-code";
import { useBarcode } from "react-barcodes";
import {
  checkUmlaute,
  getLanguageString,
  replaceUmlaute,
} from "../functions/helpers";

export interface IQRPreviewProps {
  eventID: string;
  type: "small" | "big" | "barcode";
}

const Barcode = ({ data }: any) => {
  data = replaceUmlaute(data);
  const { inputRef } = useBarcode({
    value: data,
  });

  return <svg ref={inputRef} />;
};

const QRPreview = ({ eventID, type }: IQRPreviewProps): JSX.Element => {
  // const isUUID = uuid.validate(eventID.split("_")[1]);

  if (type === "small") {
    return (
      <Box w="100%">
        <Box position="absolute" m="30px">
          <Image w="32px" h="32px" src="logo192.png" bg="white" />
        </Box>
        <QRCode level={"H"} size={92} value={eventID.toString()} />
      </Box>
    );
  }
  if (type === "big") {
    return <QRCode level={"H"} value={eventID.toString()} />;
  }

  if (type === "barcode") {
    if (checkUmlaute(eventID)) {
      return (
        <Box>
          {getLanguageString() === "deutsch"
            ? "Barcode kann nicht angezeigt werden. Bitte QR oder Text Code verwenden."
            : "Barcode cannot be displayed. Please use QR or text code."}
        </Box>
      );
    } else {
      return <Barcode data={eventID} />;
    }
  }

  return <></>;
};

export default QRPreview;
